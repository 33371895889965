import '../../../App.css';
import Typography from '@mui/material/Typography';

/**
 * ロゴ
 * @returns 
 */
function Logo() {

  const logoStyle = {
    marginTop:5,
  }

  return (
    <div style={logoStyle}>
      <Typography
        component="a"
        href="/"
        sx={{
          mr: 2,
          textDecoration: 'none',
        }}
      >
      <img src='/images/logo/logo.png' alt='ロゴ' width="90" />
        
      </Typography>
    </div>
  );

}

export default Logo;
