import React from 'react';
import { Box } from '@mui/material';
import H2 from '../../common/atoms/H2';
import textData from '../../../json/text.json';
import urlData from '../../../json/url.json';
import AccessMap from '../atoms/AccessMap';
import LargerTitle from '../atoms/LargerTitle';
import ContentText from '../../common/atoms/ContentText';

/**
 * アクセス
 * @returns 
 */
const AccessSection: React.FC = () => {

  return (
    <Box sx={{pl: {xs: '0px', md: '30px', xl: '50px'}, pr: {xs: '0px', md: '30px', xl: '50px'}}}>
        <H2 text={textData['overview.access.title']['ja']}/>
        <Box sx={{mt:5}}></Box>
      <section id="osaka">
        <LargerTitle text={textData['overview.access.osaka']['ja']} textAlign='left'/>
        <Box sx={{mt:5}}></Box>
        <AccessMap mapSrc={urlData['url.access.osaka']} title={'osaka'}/>
        <ContentText text={textData['overview.value.location.osaka']['ja']}/>
        <ContentText text={textData['overview.value.address']['ja']}/>
        <ContentText text={textData['overview.access.osaka.nearest']['ja']}/>
      </section>
      <Box sx={{mt:5}}></Box>
    </Box>
  );
};

export default AccessSection;
