import React from 'react';
import { Box, Typography, Grid, Divider } from '@mui/material';
import colorData from '../../../json/color.json';
import newsData from '../../../json/news.json';
import textData from '../../../json/text.json';
import type {NewsEntry} from '../../../types/types';

const news = { news: [...newsData.news] };
const newsList: NewsEntry[] = news.news
  .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
  .slice(0, 3)
  .flatMap((items, index) => ({
    date: items.date,
    category: items.category.ja,
    content: items.content.ja,
    url: `/news/${index}`,
  }));

const NewsSection: React.FC = () => {
  return (
    <Box
      sx={{
        minHeight: '40vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colorData['white'],
        padding: {xs: '10px', lg: '30px', xl: '50px'},
        textAlign: 'center',
        whiteSpace: 'pre-wrap',
      }}
    >
      {/* 左側の "News" タイトル */}
      <Box sx={{ flex: 1, textAlign: 'left' }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          {textData['top.news']['ja']}
        </Typography>
      </Box>
      {/* 右側のニュースリスト */}
      <Box
        sx={{
          flex: 2,
          width: '80%',
          padding: 3,
          borderRadius: '8px',
        }}
      >
        {newsList.map((news, index) => (
          <Box key={index}>
            <Grid container spacing={2} alignItems="center">
              {/* 日付部分 */}
              <Grid item xs={12} sm={3}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', color: colorData['black'] }}>
                  {news.date}
                </Typography>
              </Grid>
              {/* カテゴリー + 内容 */}
              <Grid item xs={12} sm={9} sx={{ textAlign: 'left' }}>
                <a href={news.url} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Typography variant="body1" component="span" sx={{ fontWeight: 'bold', color: colorData['crimson'] }}>
                    【{news.category}】
                  </Typography>
                  <Typography variant="body1" component="span" sx={{ marginLeft: 1 }}>
                    {news.content}
                  </Typography>
                </a>
              </Grid>
            </Grid>
            {/* 各お知らせの区切り線 */}
            {index < newsList.length - 1 && <Divider sx={{ marginY: 2 }} />}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default NewsSection;
