import React from 'react';
import { Box, Typography } from '@mui/material';
import textData from '../../../json/text.json';
import colorData from '../../../json/color.json';
import InsertImage from '../../common/atoms/InsertImage';
import LinkButton from '../atoms/LinkButton';

const ConseptSection: React.FC = () => {
  return (
    <Box
      sx={{
        minHeight: '90vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colorData['white'],
        padding: {xs: '10px', lg: '30px', xl: '50px'},
        textAlign: 'center',
        whiteSpace: 'pre-wrap',
      }}
    >
      <Box sx={{mt:10}}></Box>
      <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
        {textData['top.mission']['ja']}
      </Typography>
      <Typography variant="h4" gutterBottom sx={{fontSize: {xs: '20px', lg: '30px', xl: '60px'}}}>
        {textData['top.mission.title']['ja']}
      </Typography>
      <InsertImage imageUrl='/images/illust/IT_image1.png' title=''/>
      <Box sx={{mt: 5}}></Box>
      <Typography variant="body1" sx={{fontSize: {xs: '16px', lg: '18px', xl: '20px'}, lineHeight: '2em'}}>
        {textData['top.mission.text1']['ja']}
      </Typography>
      <Typography variant="body1" sx={{fontSize: {xs: '16px', lg: '18px', xl: '20px'}, lineHeight: '2em'}}>
        {textData['top.mission.text2']['ja']}
      </Typography>
      <Box sx={{mt:5}}></Box>
      <LinkButton title={textData['menu.company.overview']['ja']} linkTo='/overview'/>
      <Box sx={{mt:10}}></Box>
    </Box>
  );
};

export default ConseptSection;
