import '../../../App.css';
import Line from '../atoms/Line';
import Number from '../atoms/Number';
import Circle from '../atoms/Circle';

/**
 * ロゴ円モーション
 * @returns 
 */
function LogoCircle() {

  return (
    <div>
      <div style={{ position: 'absolute' }}>
        <div><Circle /></div>
        <div style={{ position: 'absolute', top: '43%', left: '-10%' }}><Number number={9} /></div>
        <div style={{ position: 'absolute', top: '47.5%', left: '50%' }}> <Line /></div>
        <div style={{ position: 'absolute', top: '87%', left: '85%' }}><Number number={5} /></div>
      </div>
    </div>
  );
}

export default LogoCircle;
