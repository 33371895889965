import React from 'react';
import '../../../App.css';
import { motion } from 'framer-motion';
import colorData from '../../../json/color.json';
import { Box } from '@mui/material';

type TextColor = keyof typeof colorData;

type Props = {
  text: string,
  time: number,
  textColor?: TextColor,
}

/**
 * テキストモーション
 * @param text string
 * @param time number
 * @returns 
 */
const TextMotion: React.FC<Props> = ({ text, time, textColor = "black" }) => {
  const BoxStyle = {
    paddingTop: 5,
    color: colorData[textColor]
  }

  return (
    <div>

      <Box sx={BoxStyle}>
        <motion.article
          initial="hidden"
          animate="visible"
          exit={{ opacity: 0, transition: { duration: 0.3 } }}
          variants={{ visible: { transition: { staggerChildren: 1 } } }}
        >
          <motion.div
            initial="hidden"
            animate="visible"
            variants={{
              hidden: { opacity: 0, y: 30 },
              visible: { opacity: 1, y: 0 },
            }}
            transition={{ duration: 0.5, delay: time }}

          >
            {text}
          </motion.div>
        </motion.article>
      </Box>



    </div>

  );
}

export default TextMotion;
