import React from 'react';
import { Typography } from '@mui/material';
import colorData from '../../../json/color.json';

interface props {
  text: string
}

/**
 * スキル表示コンポーネント
 * @param list []
 * @returns 
 */
const SkillItem: React.FC<props> = ({text}) => {
  const containerStyle = {
    display: 'inline-block',
    padding: '10px',
    margin: '3px',
    borderRadius: '10px',
    backgroundColor: colorData['orange'],
    color: colorData['white']
  };

    return (
      <Typography sx={containerStyle}>
        {text}
      </Typography>
    );
  };
  
  export default SkillItem;