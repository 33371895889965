import { Box, Typography } from '@mui/material';
import '../../../App.css';
import colorData from '../../../json/color.json';

type props = {
  text: string;
}

/**
 * 大きいテキストコンポーネント
 * @param text string
 * @returns 
 */
const LargerText: React.FC<props> = ({ text }) => {
  
  const textStyle ={
    color:colorData['black'],
    fontSize:40,
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    lineHeight: 2
  }
 

  return (
    <Box style={{paddingTop:30}}>
      <Typography variant="h2" sx={textStyle}>
       {text}
      </Typography>
    </Box>
  );

}

export default LargerText;
