import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

interface ToggleButtonGroupProps {
  items: string[];
  value: string;
  onChange: (value: string) => void;
}

/**
 * トグルボタングループコンポーネント
 * @param items string[]
 * @param value string
 * @param onChange (value: string) => void
 * @returns 
 */
const ToggleButtonGroupComponent: React.FC<ToggleButtonGroupProps> = ({ items, value, onChange }) => {
  
  const toggleStyle = {
    marginRight: '10px',
    marginBottom: '5px',
    border: '2px solid #376464',
    borderRadius: '15px'
  };

  return (
    <ToggleButtonGroup
      exclusive
      value={value}
      onChange={(e, newValue) => {
        if (newValue !== null) {
          onChange(newValue as string);
        }
      }}
      aria-label="category"
      sx={{display: 'flex', flexWrap: 'wrap', maxWidth: '600px'}}
    >
      {items.map((item, index) => (
        <ToggleButton key={index} value={item} aria-label={item} style={toggleStyle}>
          {item}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

export default ToggleButtonGroupComponent;
