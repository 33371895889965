import React, { useState } from 'react';
import '../../../App.css';
import axios from 'axios';
import { Button, TextField, FormControl, InputLabel, Grid, Checkbox, Typography, FormControlLabel } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import ToggleButtonGroupComponent from '../atoms/ToggleButtonGroupComponent';
import textData from '../../../json/text.json';


interface AxiosError {
  response?: {
    data?: {
      errors?: { [key: string]: string[] };
    };
  };
}
/**
 * お問い合わせフォーム
 * @returns 
 */
const Form: React.FC = () => {
  const navigate = useNavigate();

  const ContactCategorys = [
    textData['contact.choices1']['ja'],
    textData['contact.choices2']['ja'],
    textData['contact.choices3']['ja'],
    textData['contact.choices4']['ja'],
    textData['contact.choices5']['ja']
  ];

  const APP_PHP = process.env['REACT_APP_PHP_ENDPORINT_PREFIX'];

  const [formData, setFormData] = useState({
    company_name: '',
    user_name: '',
    post_code: '',
    address: '',
    tel_number: '',
    email: '',
    email_confirmation: '',
    category: '資料請求',
    substance: '',
    form: 'お問合せ',
    hp: ''
  });
  const [isHovered, setIsHovered] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string[] }>({});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target as HTMLInputElement;
    setFormData(prev => ({ ...prev, [name]: value }));
  };
  // メール送信API
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${APP_PHP}/taika-send-email`, formData);
      console.log(response.data);
      if (response.data.validationErrors) {
        setValidationErrors(response.data.validationErrors);
        alert('入力内容に誤りがあります');
      } else {
        alert('お問合せが完了しました！');
        setValidationErrors({});
        navigate('/success', { state: { formData } });
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.data && axiosError.response.data.errors) {
        setValidationErrors(axiosError.response.data.errors);
      } else {
        console.error("There was an error sending the email", error);
      }
    }
  }
  // 必須項目と同意にチェックがあるか確認
  const allRequiredFieldsFilled = () => {
    const { user_name, email, email_confirmation, category } = formData;
    return  user_name && email && email_confirmation && category && isAgreed;
  };
  // バリデーションエラー
  const getValidationError = (fieldName: string): string | null => {
    return validationErrors[fieldName] ? validationErrors[fieldName][0] : null;
  };
  // 送信ボタンカスタム
  const getButtonStyle = () => ({
    fontSize: '20px',
    padding: '10px 40px',
    backgroundColor: allRequiredFieldsFilled()
      ? (isHovered ? '#2A5A5A' : '#376464')  // 有効な場合、ホバー時に色を薄くします
      : '#A9A9A9',  // 無効時の色
    color: '#fff',
    borderRadius: '30px'
  });

  // material-uiカスタム
  const theme = createTheme({
    palette: {
      primary: {
        main: '#414141',
        contrastText: '#fff'
      },
    },
    components: {
      MuiToggleButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: '#376464',
              color: '#fff',
              '&:hover': {
                backgroundColor: '#2a4d4d',
              }
            }
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#4d8f8f', // ホバー時の枠線の色
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#EF5321', // フォーカス時の枠線の色
            },
            '&.Mui-focused .MuiOutlinedInput-input': { // この行を追加
              backgroundColor: 'transparent',       // この行を追加
            },
          },
          input: {
            backgroundColor: 'transparent',
          },
          notchedOutline: {
            borderColor: '#376464',
            borderRadius: '15px',
            border: '2px solid #376464',
          },
          adornedEnd: {
            '&.Mui-focused': {
              backgroundColor: 'transparent',  // この行を追加
            }
          },
          adornedStart: {
            '&.Mui-focused': {
              backgroundColor: 'transparent',  // この行を追加
            }
          }
        }
      }
    }
  });
  // 必須文字のスタイル
  const requireStyle = {
    color: 'red',
    marginLeft: '10px',
    marginRight: '10px'
  };

  return (
    <div>
      <Grid container alignItems='center' justifyContent='center' spacing={3}>
        <Grid item xs={10}>
          <ThemeProvider theme={theme}>
            <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleSubmit(e)}>
              <input type="hidden" name="hp" id="hp" />

              <Grid container spacing={3}>

                <Grid item xs={12} md={4}>
                  <InputLabel htmlFor="category">
                    お問い合わせの種類
                    <Typography component="span" style={requireStyle}>※必須</Typography>
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={8}>
                  <FormControl component="fieldset" fullWidth>
                    <ToggleButtonGroupComponent
                      items={ContactCategorys}
                      value={formData.category}
                      onChange={(newValue) => {
                        setFormData(prev => ({ ...prev, category: newValue }));
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                  <InputLabel htmlFor="substance">
                    お問い合わせ内容
                    <Typography component="span" style={requireStyle}>※必須</Typography>
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={8}>
                  <FormControl component="fieldset" fullWidth>
                    <TextField
                      id="substance"
                      name="substance"
                      value={formData.substance}
                      onChange={handleChange}
                      fullWidth
                      multiline    // 複数行のテキストエリアにする
                      rows={8}    // デフォルトで5行分の高さを持つ
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                  <InputLabel htmlFor="user_name">
                    お名前
                    <Typography component="span" style={requireStyle}>※必須</Typography>
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    id="user_name"
                    name="user_name"
                    value={formData.user_name}
                    onChange={handleChange}
                    fullWidth
                    error={!!getValidationError('user_name')}
                    helperText={getValidationError('user_name')}
                    label={textData['contact.example1']['ja']}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <InputLabel htmlFor="company_name">
                    御社名
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    id="company_name"
                    name="company_name"
                    value={formData.company_name}
                    onChange={handleChange}
                    fullWidth
                    error={!!getValidationError('company_name')}
                    helperText={getValidationError('company_name')}
                    label={textData['contact.example2']['ja']}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <InputLabel htmlFor="post_code">
                    郵便番号
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    id="post_code"
                    name="post_code"
                    value={formData.post_code}
                    onChange={handleChange}
                    fullWidth
                    error={!!getValidationError('post_code')}
                    helperText={getValidationError('post_code')}
                    label={textData['contact.example3']['ja']}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <InputLabel htmlFor="address">
                    ご住所
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    id="address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    fullWidth
                    error={!!getValidationError('address')}
                    helperText={getValidationError('address')}
                    label={textData['contact.example4']['ja']}
                  />
                </Grid>


                <Grid item xs={12} md={4}>
                  <InputLabel htmlFor="tel_number">
                    お電話番号
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    id="tel_number"
                    name="tel_number"
                    value={formData.tel_number}
                    onChange={handleChange}
                    fullWidth
                    error={!!getValidationError('tel_number')}
                    helperText={getValidationError('tel_number')}
                    label={textData['contact.example5']['ja']}
                  />
                </Grid>


                <Grid item xs={12} md={4}>
                  <InputLabel htmlFor="email">
                    メールアドレス
                    <Typography component="span" style={requireStyle}>※必須</Typography>
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    fullWidth
                    error={!!getValidationError('email')}
                    helperText={getValidationError('email')}
                    label={textData['contact.example6']['ja']}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <InputLabel htmlFor="email_confirmation">
                    メールアドレス（確認）
                    <Typography component="span" style={requireStyle}>※必須</Typography>
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    id="email_confirmation"
                    name="email_confirmation"
                    value={formData.email_confirmation}
                    onChange={handleChange}
                    fullWidth
                    error={!!getValidationError('email_confirmation')}
                    helperText={getValidationError('email_confirmation')}
                    label={textData['contact.example6']['ja']}
                  />
                </Grid>

                <Grid item xs={12}>
                  <div style={{ borderTop: '2px dashed #376464', margin: '20px 0' }}></div>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Typography>
                    当社の
                    <a href="https://taika-solution.co.jp/privacy.html#ppHandling" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                      <Typography component="span" style={requireStyle}>個人情報の取り扱いについて</Typography>
                    </a>
                    の内容をご確認いただき、下記にチェックを入れてください。
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isAgreed}
                        onChange={(e) => setIsAgreed(e.target.checked)}
                        name="agreed"
                        color="primary"
                      />
                    }
                    label="同意する"
                  />
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Button
                    type="submit"
                    variant="text"
                    color="primary"
                    style={getButtonStyle()}
                    disabled={!allRequiredFieldsFilled()}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    上記の内容で問い合わせる
                  </Button>
                </Grid>
              </Grid>
            </form>
          </ThemeProvider>
        </Grid>
      </Grid>
    </div>
  );
}

export default Form;

