import React from 'react';
import ServiceSection from '../molecules/ServiceSection';


/**
 * キメラワークスとはのコンテンツ群
 * @returns 
 */
const ConceptContentGroups: React.FC = () => {
  return (
    <div>
      <ServiceSection />
    </div>
  );
};

export default ConceptContentGroups;
