import React from 'react';
import RowButton from '../../common/molecules/RowButton';
import textData from '../../../json/text.json';
import SesSelection from '../molecules/SesSelection';
import SkillSelection from '../molecules/SkillSelection';
import IconAndText from '../../common/atoms/IconAndText';

/**
 * SESのコンテンツ群
 * @returns 
 */
const SesContentGroups: React.FC = () => {
  return (
    <div>
      <SesSelection />
      <SkillSelection />
      <RowButton
      url_left='/logistics' 
      name_left={<IconAndText text={textData['menu.service.logistics']['ja']}/>}
      url_right='/ownProducts' 
      name_right={<IconAndText text={textData['menu.service.ownProducts']['ja']}/>}
      />
    </div>
  );
};

export default SesContentGroups;
