import { Box } from '@mui/material';
import '../../../App.css';
import colorData from '../../../json/color.json';

type props = {
  firstText: string;
  secondText: string;
  thirdText: string;
}
/**
 * 経営理念のテキストコンポーネント
 * @param firstText string
 * @param secondText string
 * @param thirdText string
 * @returns 
 */
const VisionText: React.FC<props> = ({ firstText, secondText, thirdText }) => {
  
  const textStyle = {
    color:colorData['black'],
    fontSize:32,
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    lineHeight: 2,
    fontWeight: 'bold'
  }

  const spanStyle: React.CSSProperties = {
    color:colorData['red'],
    margin: '0 20px 0 20px'
  }

  return (
    <Box style={{paddingTop:30}} sx={textStyle}>
       {firstText}
      <span style={spanStyle}>
       {secondText}
      </span>
       {thirdText}
    </Box>
  );

}

export default VisionText;
