import React from 'react';
import { Box } from '@mui/material';
import H2 from '../../common/atoms/H2';
import ContentText from '../../common/atoms/ContentText';
import textData from '../../../json/text.json';
import worksData from '../../../json/works.json';
import type {CategorizedData, Achievement} from '../../../types/types';
import AchievementsSlider from '../../common/atoms/AchievementsSlider';
import InsertImage from '../../common/atoms/InsertImage';

const categorizedData: CategorizedData = {
  logistics: [],
  energy: [],
  product: [],
  development: [],
};
const works = { works: [...worksData.works] };
works.works.forEach((work, index) => {
  if (work.energy !== undefined) {
    categorizedData.energy.push({ ...work.energy, index });
  }
});
const energyAchievements: Achievement[] = categorizedData.energy.map(items => ({
  title: items.title.ja,
  imageUrl: items.image[0],
  linkTo: `/works/energy/${items.index}`
}));
/**
 * 研究開発
 * @returns 
 */
const ResearchSelection: React.FC = () => {
  return (
    <Box>
      <ContentText text={textData['research.text1']['ja']}/>
      <Box sx={{mt:5}}></Box>
      <InsertImage imageUrl={'/images/illust/research_image.jpg'} title={textData['concept.subtitle2']['ja']} />
      <Box sx={{mt:5}}></Box>
      <ContentText text={textData['research.text2']['ja']}/>
      <Box sx={{mt:5}}></Box>
      <H2 text={textData['research.sub1.title']['ja']} />
      <Box sx={{mt:5}}></Box>
      <ContentText text={textData['research.sub1.text']['ja']} />
      <Box sx={{mt:5}}></Box>
      <H2 text={textData['research.sub2.title']['ja']} />
      <Box sx={{mt:5}}></Box>
      <ContentText text={textData['research.sub2.text']['ja']} />
      <Box sx={{mt:10}}></Box>
      <AchievementsSlider achievements={energyAchievements}/>
    <Box sx={{mt:10}}></Box>
    </Box>
  );
};

export default ResearchSelection;
