import React from 'react';
import PhilosophySection from '../molecules/PhilosophySection';
import RowButton from '../../common/molecules/RowButton';
import textData from '../../../json/text.json';
import IconAndText from '../../common/atoms/IconAndText';

/**
 * 経営理念のコンテンツ群
 * @returns 
 */
const PhilosophyContentGroups: React.FC = () => {
  return (
    <div>
      <PhilosophySection />
      <RowButton  
      url_left='/overview' 
      name_left={<IconAndText text={textData['menu.company.overview']['ja']}/>}
      url_right='/staff' 
      name_right={<IconAndText text={textData['menu.company.staff']['ja']}/>}
      />
    </div>
  );
};

export default PhilosophyContentGroups;
