import React from 'react';
import newsData from '../../../json/news.json';
import NewsContentGroups from './NewsContentGroups';
import type {NewsAchievement} from '../../../types/types';
import ContainerContents from '../../common/molecules/ContainerContents';

const news = { news: [...newsData.news] };
const newsList: NewsAchievement[] = news.news
  .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
  .flatMap((items, index) => ({
    title: items.content.ja,
    imageUrl: items.image[0],
    linkTo: `/news/${index}`,
    date: items.date
  }));
/**
 * お知らせ一覧
 * @returns 
 */
const NewsContent: React.FC = () => {
  return (
    <ContainerContents children={<NewsContentGroups newsList={newsList}/>}/>
  );
};

export default NewsContent;
