import React, { useEffect, useState } from 'react';
import '../../../App.css';
import { motion, AnimatePresence } from 'framer-motion';
import Box from '@mui/material/Box';

/**
 * 台形モーション3D
 * @returns 
 */
function Rhombus3D() {

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(false);
    }, 10000
    ); // 10秒後に非表示にする

    return () => clearTimeout(timeoutId);
  }, []);

  if (!isVisible) {
    return null; // コンポーネントを非表示にする
  }

  const BoxStyle = {
    width: 500,
    height: 500,
  }

  const frontStyle = {
    width: 150,
    height: 140,
    backgroundColor: '#9a5d19',
    transform: 'scaleX(2) skewX(-27deg)',
  }
  const topStyle = {
    width: 293,
    height: 205,
    backgroundColor: '#e09642',
    transform: 'rotate(-9.5deg) scaleX(1) skew(27deg, 9.5deg)',
  }
  const leftSideStyle = {
    width: 211,
    height: 195,
    backgroundColor: '#60380b',
    transform: 'rotate(35.5deg) skew(-9.5deg, 22deg)',
  }

  return (
    <div>
      <AnimatePresence>
        <motion.div
          initial={{ y: -300, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1, delay: 8, ease: 'easeOut' }}
        >
              <div style={{ ...BoxStyle, position: 'absolute' }}>
                <div style={{ position: 'absolute', top: '48%', left: '11%' }} ><Box style={topStyle} /></div>
                <div style={{ position: 'absolute', top: '62.5%', left: '-23%' }} ><Box style={leftSideStyle} /></div>
                <div style={{ position: 'absolute', top: '87%', left: '24.5%' }} ><Box style={frontStyle} /></div>
              </div>

        </motion.div>
      </AnimatePresence>
    </div>

  );
}

export default Rhombus3D;

