import React from 'react';
import OwnProductsContentGroups from './OwnProductsContentGroups';
import ContainerContents from '../../common/molecules/ContainerContents';

/**
 * 自社商品
 * @returns 
 */
const OwnProductsContent: React.FC = () => {
  return (
    <ContainerContents children={<OwnProductsContentGroups />}/>
  );
};

export default OwnProductsContent;
