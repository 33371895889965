import '../App.css';
import Grid from '@mui/material/Grid';
import LogoCircle from '../components/motion/molecules/LogoCircle';
import CatchCopy from '../components/motion/molecules/CatchCopy';
import LogoRhombus from '../components/motion/molecules/LogoRhombus';

function Demo() {


  return (
    <div>

      <Grid container alignItems='center' justifyContent='center' style={{ paddingTop: 50 }}>


          <CatchCopy />
          <LogoCircle />
          <LogoRhombus />
      
      </Grid>

      
     
     
 



    </div>
  );
}

export default Demo;
