import React from 'react';
import RecordSelection from '../molecules/RecordSelection';
import type {Achievement} from '../../../types/types';

interface Props {
  achievements: Achievement[];
}

/**
 * 実績一覧のコンテンツ群
 * @returns 
 */
const WorksContentGroups: React.FC<Props> = ({achievements}) => {
  return (
    <div>
      <RecordSelection achievements={achievements}/>
    </div>
  );
};

export default WorksContentGroups;
