import React from 'react';
import type {NewsData} from '../../../types/types';
import { Box, Typography } from '@mui/material';
import Text from '../atoms/Text';
import colorData from '../../../json/color.json';

interface Props {
  newsData: NewsData;
}

/**
 * コンテンツ表示テンプレート２
 * @param workEntry
 * @returns 
 */
const TypeA: React.FC<Props> = ({newsData}) => {
  const imageStyle = {
    maxWidth: '80%',
    border: `2px solid ${colorData['black']}`,
    borderRadius: '8px',
  };
  return (
    <>
      <Box sx={{mt:7}}/>
      {newsData.image[1] ? (
        <img
          src={newsData.image[1]}
          alt={newsData.content.ja}
          style={imageStyle}
        />
      ):(
        <></>
      )}
      <Text text={newsData.text.ja[0]}/>
      {newsData.other && newsData.other.length > 0 && (
        newsData.other.map((item, index) => (
          <Box key={index} sx={{textAlign: 'left'}}>
            <Typography
              component='a'
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.title}
            </Typography>
          </Box>
        ))
      )}
      <Box sx={{mt:7}}/>
      <Text text={`投稿日：${newsData.date}`}/>
    </>
  );
};

export default TypeA;
