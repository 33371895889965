import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import H2 from '../../common/atoms/H2';
import InsertImage from '../../common/atoms/InsertImage';
import textData from '../../../json/text.json';
import ContentText from '../../common/atoms/ContentText';
import { useScrollToAnchor } from '../../../hooks/useScrollToAnchor';

/**
 * 事業案内
 * @returns 
 */
const ServiceSection: React.FC = () => {
  const { scrollToAnchor } = useScrollToAnchor({ smooth: true });

  useEffect(() => {
    scrollToAnchor();
  }, [scrollToAnchor]);

  return (
    <Box>
        <Box sx={{mt:5}} id='consulting'> </Box>
        <H2 text={textData['service.consulting']['ja']}/>
        <Box sx={{mt:5}}></Box>
        <InsertImage imageUrl='/images/photo/consulting_image.png' title={textData['service.consulting']['ja']} />
        <Box sx={{mt:5}}></Box>
        <ContentText text={textData['service.consulting.text']['ja']}/>
        <Box sx={{mt:10}} id='development'></Box>
        <H2 text={textData['service.development']['ja']}/>
        <Box sx={{mt:5}}></Box>
        <InsertImage imageUrl='/images/photo/develop_image.png' title={textData['service.development']['ja']} />
        <Box sx={{mt:5}}></Box>
        <ContentText text={textData['service.development.text']['ja']}/>
        <Box sx={{mt:10}} id='eco'></Box>
        <H2 text={textData['service.eco']['ja']}/>
        <Box sx={{mt:5}}></Box>
        <InsertImage imageUrl='/images/photo/eco_image.png' title={textData['service.eco']['ja']} />
        <Box sx={{mt:5}}></Box>
        <ContentText text={textData['service.eco.text']['ja']}/>
        <Box sx={{mt:10}}></Box>
    </Box>
  );
};

export default ServiceSection;
