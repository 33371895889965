import React from 'react';
import { Typography } from '@mui/material';
import colorData from '../../../json/color.json';

interface props {
  text: string
}

/**
 * スキル表示コンポーネント
 * @param list []
 * @returns 
 */
const Contenttitle: React.FC<props> = ({text}) => {
  const containerStyle = {
    padding: '20px',
    margin: '10px',
    borderRadius: '10px',
    color: colorData['crimson'],
    fontSize: '32px',
    textAlign: 'left'
  };

    return (
      <Typography sx={containerStyle}>
        {text}
      </Typography>
    );
  };
  
  export default Contenttitle;