import React from 'react';
import type {WorkEntry} from '../../../types/types';
import { Box } from '@mui/material';
import EmbeddedImage from '../atoms/EmbeddedImage';
import Text from '../atoms/Text';

interface Props {
  workEntry: WorkEntry;
}

/**
 * コンテンツ表示テンプレート１
 * @param workEntry
 * @returns 
 */
const TypeA: React.FC<Props> = ({workEntry}) => {
  return (
    <>
      <Box sx={{mt:7}}/>
      <EmbeddedImage imageUrl={workEntry.image[1]} title={workEntry.title.ja}/>
      <Text text={workEntry.text.ja[0]}/>
    </>
  );
};

export default TypeA;
