import { Box, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import colorData from '../../../json/color.json';

interface ImageWithTitleProps {
  imageUrl: string;
  title: string;
  linkTo: string;  
  contentsCount: number;
}

/**
 * 実績リンクとサムネイル
 * @param imageUrl string
 * @param title string
 * @param linkTo string
 * @param contentsCount number 
 * @returns 
 */
const ImageWithTitle: React.FC<ImageWithTitleProps> = ({ imageUrl, title, linkTo, contentsCount }) => {
  const boxStyle = {
    textAlign: 'center',
    height: '280px',
    pb: 5,
    '& img': {
      maxWidth: '100%',
      width: 'auto',
      height: 'auto',
      cursor: 'pointer',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      transition: 'transform 0.2s ease, box-shadow 0.2s ease',
      '&:hover': {
        boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)',
      },
      '&:active': {
        transform: 'translateY(4px)',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
      }
    }
  }
  const titleStyle = {
    pt:3,
    pb:0,
  }
  const width = (contentsCount === 2) ? '500px' : '300px';
  return (
    <Box sx={boxStyle}>
      <Link to={linkTo}>
        <img
          src={imageUrl}
          alt={title}
          style={{ width: width, cursor: 'pointer' }}
        />
      </Link>
      <Typography sx={titleStyle}><Link to={linkTo} style={{textDecoration: 'none', color: colorData['black']}}>{title}</Link></Typography>
    </Box>
  );
};

export default ImageWithTitle;
