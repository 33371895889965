import React, { useEffect } from 'react';
import type {NewsData} from '../../../types/types';
import { Box } from '@mui/material';
import Text from '../atoms/Text';
import colorData from '../../../json/color.json';
import ImageLink from '../atoms/ImageLink';

interface Props {
  newsData: NewsData;
}

/**
 * コンテンツ表示テンプレート２
 * @param workEntry
 * @returns 
 */
const TypeB: React.FC<Props> = ({newsData}) => {
  useEffect(() => {
    // Twitterのウィジェットスクリプトを読み込む
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    script.async = true;
    script.charset = 'utf-8';
    document.body.appendChild(script);

    // コンポーネントがアンマウントされた際にスクリプトをクリーンアップ
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const imageStyle = {
    maxWidth: '70%',
    border: `2px solid ${colorData['black']}`,
    borderRadius: '8px',
  };
  return (
    <>
      <Box sx={{mt:7}}/>
      {newsData.image[1] ? (
        <img
          src={newsData.image[1]}
          alt={newsData.content.ja}
          style={imageStyle}
        />
      ):(
        <></>
      )}
      <Text text={newsData.text.ja[0]}/>
      {newsData.other && newsData.other.length > 0 && newsData.other[0] && (
        <Box sx={{textAlign: 'left'}}>
          <ImageLink imageUrl={newsData.other[0].image} title={newsData.other[0].title} linkTo={newsData.other[0].url}/>
        </Box>
      )}
      {newsData.other && newsData.other.length > 0 && (
        newsData.other[0].post.map((item: any, index: React.Key | null | undefined) => (
          <Box key={index} sx={{ textAlign: 'center', marginTop: '20px' }}>
            <div dangerouslySetInnerHTML={{ __html: item }} />
          </Box>
        ))
      )}
      <Box sx={{mt:7}}/>
      <Text text={`投稿日：${newsData.date}`}/>
    </>
  );
};

export default TypeB;
