import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import colorData from '../../../json/color.json';
import skillsData from '../../../json/skills.json';
import SkillList from '../atoms/SkillList';
import SkillMultiList from '../atoms/SkillMultiList';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

/**
 * カスタムテーマ
 */
const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: colorData['crimson'],
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: colorData['crimson'],
          },
        },
      },
    },
  },
});

/**
 * カスタムタブパネル
 * @param TabPanelProps 
 * @returns 
 */
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function SkillsComponent() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const skills = skillsData;

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label={skills.processSkills.title.ja} {...a11yProps(0)} />
            <Tab label={skills.programmingLangageSkills.title.ja} {...a11yProps(1)} />
            <Tab label={skills.DBManagementSystemSkills.title.ja} {...a11yProps(2)} />
            <Tab label={skills.OperatingSystemSkills.title.ja} {...a11yProps(3)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <SkillList list={skills.processSkills.item.ja}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <SkillMultiList list={skills.programmingLangageSkills.category}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <SkillList list={skills.DBManagementSystemSkills.item}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <SkillList list={skills.OperatingSystemSkills.item}/>
        </CustomTabPanel>
      </Box>
    </ThemeProvider>
  );
}