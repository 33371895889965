import '../../App.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import textData from '../../json/text.json';
import Header from '../../components/menu/organisms/Header';
import Footer from  '../../components/footer/Footer';
import TopicPath from '../../components/common/atoms/TopicPath';
import H1 from '../../components/common/atoms/H1';
import AccessContent from '../../components/company/organisms/AccessContent';

/**
 * アクセスページ
 * @returns 
 */
function Access() {
  const links = [{link:'/access', pageName:textData['menu.access']['ja']}];
  const titleText = textData['menu.access']['ja'];
  return (

    <div>
      <HelmetProvider>
        <Helmet>
          <title>{textData['home.title']['ja']}</title>
          <meta name="description" content= {textData['menu.access']['ja']} />
        </Helmet>
      </HelmetProvider>

      <header>
        <Header />
      </header>
      
      <main className="main-content">
        <TopicPath links={links} />
        <H1 text={titleText}/>
        <AccessContent />
      </main>
      <footer>
        <Footer />
      </footer>

    </div>
  );
}

export default Access;
