import React, { useEffect, useState } from 'react';
import '../../../App.css';
import { motion } from 'framer-motion';

type Props = {
  number: number,
  fontSize?: number
};

/**
 * 数字モーション
 * @param number number
 * @param fontSize number
 * @returns 
 */
const Number: React.FC<Props>= ({number, fontSize=45}) => {

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(false);
    }, 7500); // 非表示にする

    return () => clearTimeout(timeoutId);
  }, []);

  if (!isVisible) {
    return null; // コンポーネントを非表示にする
  }

  const textStyle = {
    color: "#fc4d27",
    fontSize: fontSize,
    opacity: 0,
    fontFamily: "ヒラギノ角ゴ ProN"
  };


  return (
    <div>
      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: isVisible ? 1 : 0, scale: 1 }}
        style={textStyle}
        transition={{
          opacity: { duration: 2, ease: 'easeInOut' },
          scale: { duration: 0.5, delay: 2.5, ease: 'easeInOut' },
        }}
      >
        {number}
      </motion.div>
    </div>

  );
}

export default Number;
