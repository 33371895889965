import { Link } from 'react-router-dom';
import '../../../App.css';
import Grid from '@mui/material/Grid';
import colorData from '../../../json/color.json';
import textData from '../../../json/text.json';

/**
 * ハンバーガー左寄せ
 * @returns 
 */
function HbLeft() {

  return (
    <div >
      <div style={{ paddingLeft: 40 }}>
        <Link to='/'>
          <img src='/images/logo/logo.png' alt='ロゴ' width="200" style={{ padding: 15, backgroundColor: colorData['white'], borderRadius: '15px'}}/>
        </Link>
      </div>
      <div style={{ padding: 40 }}>
        {textData['companyName']['ja']}<br />
        {textData['postCode']['ja']}<br />
        {textData['address']['ja']}<br />
        TEL : {textData['tel']['ja']}
      </div>
      <div style={{ paddingLeft: 20 }}>
        <Grid container direction='row' >
        </Grid>
      </div>

    </div>
  );
}

export default HbLeft;