import { Box } from '@mui/material';
import React from 'react';

interface ImageLinkProps {
  imageUrl: string;
  title: string;
}

/**
 * 画像挿入
 * @param imageUrl string
 * @param title string
 * @returns 
 */
const InsertImage: React.FC<ImageLinkProps> = ({ imageUrl, title }) => {
  const containerStyle = {
    textAlign: 'center',
    position: 'relative',
  };

  const imageStyle = {
    maxWidth: '100%',
  };

  return (
    <Box sx={containerStyle}>
      <img
        src={imageUrl}
        alt={title}
        style={imageStyle}
      />
    </Box>
  );
};

export default InsertImage;
