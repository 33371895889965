import { useState, useEffect } from 'react';
import '../../../App.css';

/**
 * 画面サイズ調整
 * @returns 
 */
export function UseIsWide() {

  const [isWide, setIsWide] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsWide(window.innerWidth >= 920);
    };

    // 初期化とウィンドウのリサイズ時にリスナーを追加
    handleResize();
    window.addEventListener('resize', handleResize);

    // クリーンアップ関数
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isWide;
}
