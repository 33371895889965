import React from 'react';
import { Box, Grid } from '@mui/material';
import colorData from '../../../json/color.json';


interface Props {
  list: string[];
}

/**
 * スキル一覧表示コンポーネント
 * @param list []
 * @returns 
 */
const SkillList: React.FC<Props> = ({list}) => {
  const gridStyle = {
    textAlign: 'left',
    padding: '10px',
    margin: '2px',
    '&:hover': {
      backgroundColor: colorData['royalblue'],
    },
    borderBottom: `solid 1px ${colorData['gray']}`
  };
    return (
      <Box>
        <Grid container spacing={0} sx={{marginTop: '20px'}}>
          {list.map((item, index) => (
            <Grid item xs={12} md={3.9} key={index} sx={gridStyle}>
              {item}
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };
  
  export default SkillList;