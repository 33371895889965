import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import ImformationGrid from '../atoms/ImformationGrid';
import textData from '../../../json/text.json';
import ImformationLinkGrid from '../atoms/ImformationLinkGrid';
import useWindowSize from '../../../hooks/useWindowsSize';

/**
 * 経営理念
 * @returns 
 */
const InformationSection: React.FC = () => {
  const [width] = useWindowSize();
  const [isWide, setisWide] = useState(true);

  useEffect(() => {
    if (width === 0) {
        return setisWide(true);
    }
    if (width < 920) {
        return setisWide(false);
    }
    return setisWide(true);
}, [width]);

  return (
    <Box sx={{mt:5, mb:5}}>
      {isWide ?
      <Grid container spacing={0}>
        <ImformationGrid label='　' value='　' />
        <ImformationGrid label={textData['overview.label.company']['ja']} value={textData['overview.value.company']['ja']} />
        <ImformationGrid label='　' value='　' />
        <ImformationGrid label={textData['overview.label.location']['ja']} value={textData['overview.value.location.osaka']['ja']} />
        <ImformationLinkGrid label='アクセス' value='/access' />
        <ImformationGrid label='　' value='　' />
        <ImformationGrid label={textData['overview.label.address']['ja']} value={textData['overview.value.address']['ja']} />
        <ImformationGrid label='　' value='　' />
        <ImformationGrid label={textData['overview.label.establishment']['ja']} value={textData['overview.value.establishment']['ja']} />
        <ImformationGrid label='　' value='　' />
        <ImformationGrid label={textData['overview.label.closingmonth']['ja']} value={textData['overview.value.closingmonth']['ja']} />
        <ImformationGrid label='　' value='　' />
        <ImformationGrid label={textData['overview.label.representative']['ja']} value={textData['overview.value.representative']['ja']} />
        <ImformationGrid label='　' value='　' />
        <ImformationGrid label={textData['overview.label.summary']['ja']} value={textData['overview.value.summary']['ja']} />
        <ImformationGrid label='　' value='　' />
        <ImformationGrid label={textData['overview.label.affiliation']['ja']} value={textData['overview.value.affiliation']['ja']} />
        <ImformationGrid label='　' value='　' />
      </Grid>
       :
       <Grid container spacing={0}>
         <ImformationGrid label={textData['overview.label.company']['ja']} value={textData['overview.value.company']['ja']} />
         <ImformationGrid label={textData['overview.label.location']['ja']} value={textData['overview.value.location.osaka']['ja']} />
         <ImformationLinkGrid label='アクセス' value='/access' />
         <ImformationGrid label={textData['overview.label.address']['ja']} value={textData['overview.value.address']['ja']} />
         <ImformationGrid label={textData['overview.label.establishment']['ja']} value={textData['overview.value.establishment']['ja']} />
         <ImformationGrid label={textData['overview.label.closingmonth']['ja']} value={textData['overview.value.closingmonth']['ja']} />
         <ImformationGrid label={textData['overview.label.representative']['ja']} value={textData['overview.value.representative']['ja']} />
         <ImformationGrid label={textData['overview.label.summary']['ja']} value={textData['overview.value.summary']['ja']} />
         <ImformationGrid label={textData['overview.label.affiliation']['ja']} value={textData['overview.value.affiliation']['ja']} />
       </Grid>
      }
    </Box>
  );
};

export default InformationSection;
