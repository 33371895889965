import '../App.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import textData from '../json/text.json';
import Header from '../components/menu/organisms/Header';
import Footer from  '../components/footer/Footer';
import TopicPath from '../components/common/atoms/TopicPath';
import H1 from '../components/common/atoms/H1';
import ScrollToTopButton from '../components/common/atoms/ScrollToTopbutton';
import NewsContent from '../components/news/organisms/NewsContent';

/**
 * ニュース一覧ページ
 * @returns 
 */
function News() {
  const links = [{link:'/news', pageName:textData['menu.news']['ja']}];
  const titleText = textData['news.title']['ja'];
  return (

    <div>
      <ScrollToTopButton />
      <HelmetProvider>
        <Helmet>
          <title>{textData['home.title']['ja']}</title>
          <meta name="description" content= {textData['menu.news']['ja']} />
        </Helmet>
      </HelmetProvider>

      <header>
        <Header />
      </header>
      
      <main className="main-content">
        <TopicPath links={links} />
        <H1 text={titleText}/>
        <NewsContent />
      </main>
      <footer>
        <Footer />
      </footer>

    </div>
  );
}

export default News;
