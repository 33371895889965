import React from 'react';
import type {NewsData} from '../../../types/types';
import { Box, Container } from '@mui/material';
import colorData from '../../../json/color.json';
import DynamicComponentRenderer from '../molecules/DynamicComponentRenderer';

interface Props {
  newsData: NewsData;
}

/**
 * コンテンツ群
 * @param workEntry
 * @returns 
 */
const NewsListContent: React.FC<Props> = ({newsData}) => {
  return (
    <Container maxWidth="xl" sx={{backgroundColor: colorData['white']}}>
      <Box
        sx={{
          margin: '0 auto',
          textAlign: 'center',
          padding: '0 40px'
        }}
      >
        <DynamicComponentRenderer newsData={newsData}/>
      </Box>
    </Container>
  );
};

export default NewsListContent;
