import { Grid } from '@mui/material';
import '../../../App.css';
import colorData from '../../../json/color.json';
import LinkButton from './LinkButton';
import useWindowSize from '../../../hooks/useWindowsSize';
import { useEffect, useState } from 'react';

type props = {
  label: string;
  value: string;
}

/**
 * グリッドリンクコンポーネント
 * @param label string
 * @param value string
 * @returns 
 */
const ImformationLinkGrid: React.FC<props> = ({ label, value }) => {
  const [width] = useWindowSize();
  const [isWide, setisWide] = useState(true);

  useEffect(() => {
    if (width === 0) {
        return setisWide(true);
    }
    if (width < 920) {
        return setisWide(false);
    }
    return setisWide(true);
}, [width]);
  
  const gridLabelStyle = {
    borderRight: `5px solid ${colorData['royalblue']}`,
    textAlign: 'left',
    paddingLeft: '100px',
    fontSize: '18px',
    lineHeight: 2
  }
  const gridValueStyle = {
    textAlign: 'left',
    paddingLeft: isWide ? '100px' : '30px',
    fontSize: '18px',
    whiteSpace: 'pre-wrap',
    lineHeight: 2
  }
  return (
    <>
    {isWide ?
      <>
        <Grid item xs={12} md={3} sx={gridLabelStyle}>
          　
        </Grid>
        <Grid item xs={12} md={9} sx={gridValueStyle}>
          <LinkButton targetId={value} text={label} />
        </Grid>
      </>
      : 
      <>
        <Grid item xs={12} md={9} sx={gridValueStyle}>
          <LinkButton targetId={value} text={label} />
        </Grid>
      </>
    }
    </>
  );

}

export default ImformationLinkGrid;
