import { Box, Container } from '@mui/material';
import React from 'react';
import colorData from '../../../json/color.json';
import NewsNavigation from '../atoms/NewsNavigation';

interface links {
  link: string;
  pageName: string | undefined;
};

interface Props {
  beforeLinks?: links;
  afterLinks?: links;
};

/**
 * 記事前後ボタンコンポーネント
 * @param beforeLinks links
 * @param afterLinks links
 * @returns 
 */
const NewsNavContainer: React.FC<Props> = ({ beforeLinks, afterLinks }) => {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    textDecoration: 'none',
    color: colorData['black'],
    padding: '0 40px'
  };

  return (
    <Container maxWidth="xl" sx={{backgroundColor: colorData['white']}}>
      <Box sx={containerStyle}>
        {beforeLinks ? (
          <NewsNavigation title={beforeLinks.pageName} linkTo={beforeLinks.link} before={true} />
        ) : (
          <Box flex={1} />
        )}
        {afterLinks && (
          <Box flex={1} display="flex" justifyContent="flex-end">
            <NewsNavigation title={afterLinks.pageName} linkTo={afterLinks.link} before={false} />
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default NewsNavContainer;
