import React from 'react';
import RowButton from '../../common/molecules/RowButton';
import textData from '../../../json/text.json';
import LogisticsSection from '../molecules/LogisticsSection';
import IconAndText from '../../common/atoms/IconAndText';

/**
 * 物流コンサルティングのコンテンツ群
 * @returns 
 */
const LogisticsContentGroups: React.FC = () => {
  return (
    <div>
      <LogisticsSection />
      <RowButton  
      url_left='/ownProducts' 
      name_left={<IconAndText text={textData['menu.service.ownProducts']['ja']}/>}
      url_right='/development' 
      name_right={<IconAndText text={textData['menu.service.development']['ja']}/>}
      />
    </div>
  );
};

export default LogisticsContentGroups;
