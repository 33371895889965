import { Box, Typography } from '@mui/material';
import React from 'react';
import colorData from '../../../json/color.json';

interface ImageLinkProps {
  imageUrl: string;
  title: string;
}
/**
 * 画像埋め込み
 * @param imageUrl string
 * @param title string
 * @returns 
 */
const EmbeddedImage: React.FC<ImageLinkProps> = ({ imageUrl, title }) => {
  const containerStyle = {
    textAlign: 'center',
  };

  const imageStyle = {
    maxWidth: '100%'
  };

  const textStyle = {
    color: colorData['black'],
    fontSize: '20px',
  };

  return (
    <Box sx={containerStyle}>
      <img
          src={imageUrl}
          alt={title}
          style={imageStyle}
      />
      <Typography sx={textStyle}>{title}</Typography>
    </Box>
  );
};

export default EmbeddedImage;
