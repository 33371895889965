import React, { ReactNode, useState } from 'react';
import '../../../App.css';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import colorData from '../../../json/color.json';
import { UseIsWide } from '../../common/atoms/UseIsWide';
import CloseIcon from '@mui/icons-material/Close';
import { motion, AnimatePresence } from 'framer-motion';

type props = {
  children: ReactNode;
};

/**
 * ハンバーガーアイコン
 * @param children ReactNode
 * @returns 
 */
const HamburgerIcon: React.FC<props> = ({ children }) => {
  const isWide = UseIsWide();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const HamburgerIconStyle = {
    color: colorData['white'],
    backgroundColor: colorData['crimson'],
    fontSize: isWide ? 16 : 20,
    padding: isWide ? '5px 55px' : '5px 20px',
    transition: 'opacity 0.3s ease',
    borderRadius: 100,
  };

  const bKStyle = {
    top: 0,
    left: 0,
    width: '100%',
    minHeight: '100vh',
    backgroundColor: colorData['crimson'],
    color: '#fff',
    zIndex: 100,
  };

  const CloseBtnStyle = {
    color: colorData['white'],
    fontSize: isWide ? 80 : 40,
  };

  return (
    <Box style={{ padding: '5px' }}>
      <Button
        onClick={toggleMenu}
        style={HamburgerIconStyle}
        sx={{
          '&:hover': {
            opacity: 0.7,
          },
        }}
      >
        <MenuIcon style={{ fontSize: 30 }} />
      </Button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            style={{ ...bKStyle, overflowY: 'auto', position: 'fixed', overflow: 'scroll' }}
          >
            <Button
              onClick={toggleMenu}
              style={{ float: 'right' }}
            >
              <CloseIcon style={{ ...CloseBtnStyle, position: 'absolute', top: 20, right: 30 }} />
            </Button>
            <Box style={{ height: '100vh' }}>{children}</Box>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default HamburgerIcon;
