import React from 'react';
import staffData from '../../../json/staff.json';
import StaffContentGroups from './StaffContentGroups';
import type { President } from '../../../types/types';
import ContainerContents from '../../common/molecules/ContainerContents';

/**
 * 社員紹介概要
 * @returns 
 */
const StaffContent: React.FC = () => {
  const { tokimoto } = staffData;  
  const president:President = {
    initialImage: '/images/staff/tokimoto_1.JPG',
    hoverImage: '/images/staff/tokimoto_2.JPG',
    introductionText: {
      title: tokimoto.name.ja,
      career: tokimoto.career_array.ja,
      hobby: tokimoto.hobby.ja,
      introduction: tokimoto.introduction.ja,
      message: tokimoto.message.ja,
    }
  }
  return (
    <ContainerContents children={<StaffContentGroups president={president}/>}/>
  );
};

export default StaffContent;
