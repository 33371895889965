import '../../App.css';
import { useState, useEffect, useMemo } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import textData from '../../json/text.json';
import worksData from '../../json/works.json';
import Header from '../../components/menu/organisms/Header';
import Footer from  '../../components/footer/Footer';
import TopicPath from '../../components/common/atoms/TopicPath';
import H1 from '../../components/common/atoms/H1';
import BackgroundAnimation from '../../components/motion/atoms/BackgroundAnimation';
import { useParams } from 'react-router-dom';
import WorksAchievementsContent from '../../components/works/organisms/WorksAchievementsContent';
import NewsNavContainer from '../../components/common/molecules/NewsNavContainer';

type WorkKey = 'development' | 'logistics' | 'energy' | 'product';

/**
 * 実績ページ
 * @returns 
 */
function WorksListTemplate() {
  const works = useMemo(() => ({ works: [...worksData.works] }), []);
  const { index } = useParams();
  const achievementsIndex = Number(index);
  const isValidIndex = achievementsIndex >= 0 && achievementsIndex < works.works.length;
  // キー名のリストを定義
  const keyNames = useMemo(() => ["development", "logistics", "energy", "product"], []);
  // 現在のインデックスに対応するエントリーを取得
  const currentWork = works.works[achievementsIndex];
  // 存在するキーを探す
  const existingKey = keyNames.find(key => currentWork[key as keyof typeof currentWork]);
  const title = existingKey ? (currentWork[existingKey as keyof typeof currentWork] as any).title.ja : undefined;
  const workEntry = isValidIndex ? (currentWork[existingKey as keyof typeof currentWork] as any) : undefined;
  
  const afterIndex = useMemo(() => achievementsIndex + 1 > 0 && achievementsIndex + 1 < works.works.length ? achievementsIndex + 1 : null, [achievementsIndex, works.works.length]);
  const beforeIndex = useMemo(() => achievementsIndex - 1 >= 0 ? achievementsIndex - 1 : null, [achievementsIndex]);

  const [beforeLinks, setBeforeLinks] = useState<linksNav | undefined>();
  const [afterLinks, setAfterLinks] = useState<linksNav | undefined>();

  // 表示実績設定
  interface linksNav {
    link: string;
    pageName: string | undefined;
  }
  const links = [
    {link:'/works', pageName:textData['menu.works']['ja']},
    {link:`/works/${existingKey}/${index}`, pageName:title}
  ];
  /**
   * 次の記事と前の記事の設定処理
   */
  useEffect(() => {
    if (beforeIndex !== null) {
      const beforeWork = works.works[beforeIndex];
      const existingBeforeKey = keyNames.find(key => beforeWork[key as WorkKey]) as WorkKey | undefined;
      let beforeTitle: string | undefined = undefined;
      if (beforeIndex !== undefined && existingBeforeKey) {
        const beforeWorkEntry = beforeWork[existingBeforeKey];
        if (beforeWorkEntry) {
          beforeTitle = beforeWorkEntry.title.ja;
        }
      }
      setBeforeLinks({
        link: `/works/${existingBeforeKey}/${beforeIndex}`,
        pageName: beforeTitle,
      });
    } else {
      setBeforeLinks(undefined);
    }

    if (afterIndex !== null) {
      const afterWork = works.works[afterIndex];
      const existingAfterKey = keyNames.find(key => afterWork[key as WorkKey]) as WorkKey | undefined;
      let afterTitle: string | undefined = undefined;
      if (afterIndex !== undefined && existingAfterKey) {
        const afterWorkEntry = afterWork[existingAfterKey];
        if (afterWorkEntry) {
          afterTitle = afterWorkEntry.title.ja;
        }
      }
      setAfterLinks({
        link: `/works/${existingAfterKey}/${afterIndex}`,
        pageName: afterTitle,
      });
    } else {
      setAfterLinks(undefined);
    }
  }, [beforeIndex, afterIndex, works.works, keyNames]);
  return (

    <div>
      <BackgroundAnimation />
      <HelmetProvider>
        <Helmet>
          <title>{textData['home.title']['ja']}</title>
          <meta name="description" content= {title} />
        </Helmet>
      </HelmetProvider>

      <header>
        <Header />
      </header>
      {isValidIndex && workEntry ? (
        <main className="main-content">
          <TopicPath links={links} />
          <H1 text={title}/>
          <WorksAchievementsContent workEntry={workEntry} />
          <NewsNavContainer beforeLinks={beforeLinks} afterLinks={afterLinks}/>
        </main>
      ) : (
        <div>コンテンツが見つかりません。</div>
      )}
      <footer>
        <Footer />
      </footer>

    </div>
  );
}

export default WorksListTemplate;
