import '../App.css';
import { motion } from 'framer-motion';
import Grid from '@mui/material/Grid';

function Demo() {

  const rhombusStyle = {
    width: 150,
    height: 140,
    backgroundColor: '#106520',
    transform: 'scaleX(2) skewX(-27deg)',
  }


  const draw = {
    hidden: { pathLength: 0, opacity: 0, rotate: 0 },
    visible: (i: number) => {
      const delay = 2.5 + i * 0.5;
      return {
        pathLength: 0.632,
        opacity: 1,
        rotate: 180,
        transition: {
          pathLength: { delay, type: "linear", duration: 2, bounce: 0 },
          opacity: { delay, duration: 0.01 },
        }
      };
    }
  };

  return (
    <div>

      <Grid container alignItems='center' justifyContent='center' style={{ paddingTop: 250 }}>
        <div style={{ position: 'absolute' }}>
          <motion.svg
            width="1200px"
            height="600px"
            viewBox="0 0 500% 500%"
            initial="hidden"
            animate="visible"
          >
            <motion.circle
              cx="75%"
              cy="60%"
              r="125"
              stroke="#fc4d27"
              variants={draw}
              custom={0}
            />
          </motion.svg>
          
          <div style={{ position: 'absolute', top: '90%', left: '64.5%' }}>
            <motion.div
              className="box"
              initial={{ x: '-1000%' }}
              animate={{ x: '0%' }}
              transition={{ duration: 1.5, ease: 'easeOut', delay: 3 }}
            >
              <div style={rhombusStyle} />
            </motion.div>
          </div>
        </div>

        <div style={{ position: 'absolute', top: '30%', left: '22%' }}>
          <motion.div
            className="box"
            initial={{ x: '-1000%' }} // 初期位置を画面の左外側に設定
            animate={{ x: '0%' }} // x座標を0%までアニメーション
            transition={{ duration: 2, ease: 'easeOut' }} // アニメーションの速さとイージングを設定
            style={{ fontSize: '36px' }}
          >
            お客様の挑戦に共感し<br />共に挑むITパートナー
            <div style={{ paddingTop: 30 }}>
              <motion.div
                className="box"
                initial={{ x: '-1000%' }}
                animate={{ x: '0%' }}
                transition={{ duration: 2, ease: 'easeOut', delay: 2 }}
                style={{ fontSize: '20px' }}
              >
                お客様と連携し、共に課題に立ち向かい、<br />持続可能なソリューションを見つけるために<br />私たちはITの専門知識とスキルを提供します。
              </motion.div>
            </div>
          </motion.div>
        </div>
      </Grid>


    </div>
  );
}

export default Demo;
