import '../../../App.css';
import Box from '@mui/material/Box';
import TextMotion from '../atoms/TextMotion';
import useWindowSize from '../../../hooks/useWindowsSize';
import { useEffect, useState } from 'react';

/**
 * テキストモーション
 * @returns 
 */
function MVtext() {
  const [width] = useWindowSize();
  const [isWide, setisWide] = useState(true);

  useEffect(() => {
    if (width === 0) {
        return setisWide(true);
    }
    if (width < 920) {
        return setisWide(false);
    }
    return setisWide(true);
}, [width]);


  return (
    <div>
      {isWide ? 
        <>
          <Box sx={{position: 'absolute', top: '10%', left: '65%'}}>
              <Box sx={{ fontSize: '42px', marginTop: '30px', writingMode: 'vertical-rl' }}>
                <TextMotion text='おもしろき' time={0.5} />
                <TextMotion text='こともなき世を' time={1.7} />
                <TextMotion text='おもしろく' time={3} textColor={"crimson"}/>
              </Box>
          </Box>
          <Box sx={{position: 'absolute',top: '70%', left: '64%', fontSize: '20px', marginTop: '30px' }}>
            <TextMotion text='面白い未来を創るIT企業' time={4} />
          </Box>
        </>
        :
        <>
          <Box sx={{position: 'absolute', top: '40%', left: '60%'}}>
              <Box sx={{ fontSize: '16px', marginTop: '30px', writingMode: 'vertical-rl' }}>
                <TextMotion text='おもしろき' time={0.5} />
                <TextMotion text='こともなき世を' time={1.7} />
                <TextMotion text='おもしろく' time={3} />
              </Box>
          </Box>
          <Box sx={{position: 'absolute',top: '70%', left: '50%', fontSize: '14px', marginTop: '30px' }}>
            <TextMotion text='面白い未来を創るIT企業' time={4} />
          </Box>
        </>
      }
    </div>

  );
}

export default MVtext;
