import React from 'react';
import '../../../App.css';
import Grid from "@mui/material/Grid";
import RightGreenButton from '../atoms/RightGreenButton';

type props = {
  url_left: string;
  name_left: any;
  url_right: string;
  name_right: any;
}
/**
 * 左右リンクボタンコンポーネント
 * @param url_left string
 * @param name_left any
 * @param url_right string
 * @param name_right any
 * @returns 
 */
const RowButton: React.FC<props> = ({ url_left, name_left, url_right, name_right}) => {

  return (
    <div>
      <Grid container alignItems='center' justifyContent='center' direction='row' >
        <div style={{ padding: 20 }}>
         <RightGreenButton url={url_left}  name={name_left} fontSize={20} borderRadius={20} padding='20px 100px 20px 70px' />
        </div>
        <div style={{ padding: 30 }}>
          <RightGreenButton url={url_right}  name={name_right} fontSize={20} borderRadius={20}  padding='20px 100px 20px 70px'/>
        </div>
      </Grid>

    </div>
  );
};

export default RowButton;
