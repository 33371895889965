import React from 'react';
import { Box, Container } from '@mui/material';
import colorData from '../../../json/color.json';

interface Props {
  children: React.ReactNode;
}

/**
 * コンテナ
 * @returns 
 */
const ContainerContents: React.FC<Props> = ({children}) => {
  return (
    <Container maxWidth="xl" sx={{backgroundColor: colorData['white']}}>
      <Box
        sx={{
          margin: '0 auto',
          textAlign: 'center',
          padding: '0 40px'
        }}
      >
        {children}
      </Box>
    </Container>
  );
};

export default ContainerContents;
