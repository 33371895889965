import React from 'react';
import AccessSection from '../molecules/AccessSection';


/**
 * アクセスのコンテンツ群
 * @returns 
 */
const AccessContentGroups: React.FC = () => {
  return (
    <div>
      <AccessSection />
    </div>
  );
};

export default AccessContentGroups;
