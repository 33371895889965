import { Box, Typography } from '@mui/material';
import '../../../App.css';
import colorData from '../../../json/color.json';
import { UseIsWide } from '../../common/atoms/UseIsWide';

type props = {
  text: string;
}

/**
 * H2テキストコンポーネント
 * @param text string
 * @returns 
 */
const H2: React.FC<props> = ({ text }) => {

  const isWide = UseIsWide();

  const textStyle ={
    color:colorData['black'],
    fontSize:{xs: '18px', md: '26px', xl: '32px'},
    marginBottom: 0
  }

  const  crimsonBorderStyle = {
    border: `2px solid ${colorData['crimson']}`,
    width:isWide? 700:'auto',
    margin:  '0 auto',
  }

  const  royalblueBorderStyle = {
    border: `2px solid ${colorData['royalblue']}`,
    width:isWide? 700:'auto',
    margin:  '0 auto',
    marginTop:1,
  }
  

  return (
    <Box style={{paddingTop:40, textAlign:'center'}}>
      <Typography variant="h2" sx={textStyle}>
       {text}
      </Typography>
      <Box sx={crimsonBorderStyle}></Box>
      <Box sx={royalblueBorderStyle}></Box>
    </Box>
  );

}

export default H2;
