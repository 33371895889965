import '../../../App.css';
import Text from '../atoms/Text';

/**
 * キャッチコピーモーション
 * @returns 
 */
function CatchCopy() {

  return (
    <div>
      <div style={{ position: 'absolute', top: '30%', left: '13%' }}>
        <div style={{ fontSize: '36px' }}>
          <Text text='お客様の挑戦に共感し' time={0.5} />
          <Text text='共に挑むITパートナー' time={0.5} />
        </div>
        <div style={{ fontSize: '20px', marginTop: '30px' }}>
          <Text text='お客様と連携し、共に課題に立ち向かい、' time={1.5} />
          <Text text='持続可能なソリューションを見つけるために' time={1.5} />
          <Text text='私たちはITの専門知識とスキルを提供します。' time={1.5} />
        </div>
      </div>
    </div>
  );
}

export default CatchCopy;
