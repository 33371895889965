import React from 'react';
import ResearchContentGroups from './ResearchContentGroups';
import ContainerContents from '../../common/molecules/ContainerContents';

/**
 * 研究開発
 * @returns 
 */
const ResearchContent: React.FC = () => {
  return (
    <ContainerContents children={<ResearchContentGroups />}/>
  );
};

export default ResearchContent;
