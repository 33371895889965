import { Box, Typography } from '@mui/material';
import React from 'react';
import colorData from '../../../json/color.json';

interface ImageLinkProps {
  imageUrl: string;
  title: string;
  linkTo: string;
}

/**
 * 画像リンク
 * @param imageUrl string
 * @param title string
 * @param linkTo string
 * @returns 
 */
const ImageLink: React.FC<ImageLinkProps> = ({ imageUrl, title, linkTo }) => {
  const containerStyle = {
    textAlign: 'center',
    position: 'relative',
  };

  const imageStyle = {
    maxWidth: '100%',
    cursor: 'pointer',
  };

  const textStyle = {
    color: colorData['black'],
    position: 'absolute',
    top: '50%',
    marginleft: '-30px',
    padding: '8px 16px',
    fontSize: {xs: '14px', md: '24px', xl: '28px'},
    fontWeght: 'bolder',
    backgroundColor: 'rgba(255,255,255, 0.7)',
    textAlign: 'left'
  };

  return (
    <Box sx={containerStyle}>
      <Typography
        component='a'
        href={linkTo}
        sx={{
          textDecoration: 'none',
        }}
      >
        <img
          src={imageUrl}
          alt={title}
          style={imageStyle}
        />
        <Typography sx={textStyle}>{title}</Typography>
      </Typography>
    </Box>
  );
};

export default ImageLink;
