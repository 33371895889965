import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; 
import InterviewSection from './InterviewSection';

interface AccordionTextProps {
  title: string;
  career: string[];
  hobby: string;
  introduction: string;
  message: string;
}

/**
 * アコーディオン
 * @param title string
 * @param content string
 * @returns 
 */
const AccordionSection: React.FC<AccordionTextProps> = ({ title, career, hobby, introduction, message }) => {
  const [expanded, setExpanded] = useState(false);

  const handleAccordionClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box sx={{ml:2, mr:2}}>
      <Accordion expanded={expanded} onChange={handleAccordionClick}>
        <AccordionSummary
          aria-controls="panel-content"
          id="panel-header"
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="h6" sx={{ width: '100%' }}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ textAlign: 'left' }}>
            <InterviewSection career={career} hobby={hobby} introduction={introduction} message={message} />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default AccordionSection;
