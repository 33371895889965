import '../../../App.css';
import colorData from '../../../json/color.json';
import { Box, Typography } from '@mui/material';

type props = {
  text: string;
  textAlign?: 'left' | 'right'| 'center';
}

/**
 * 説明テキスト
 * @param text string
 * @param textAlign 'left' | 'right' | 'center'
 * @returns 
 */
const ContentHeadLine: React.FC<props> = ({ text, textAlign = 'left' }) => {
  
  const textStyle ={
    color:colorData['black'],
    fontSize:30,
    textAlign: textAlign,
    whiteSpace: 'pre-wrap',
    lineHeight: 2
  }
 

  return (
    <Box style={{paddingTop:30}}>
      <Typography variant="body1" sx={textStyle}>
       {text}
      </Typography>
    </Box>
  );

}

export default ContentHeadLine;
