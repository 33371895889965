import '../../../App.css';
import colorData from '../../../json/color.json';
import { UseIsWide } from './UseIsWide';
import Grid from '@mui/material/Grid';
import { Link } from "react-router-dom";
import { Box } from '@mui/material';
import React from 'react';

type links = {
  link: string;
  pageName?: string | undefined;
};

type TopicPathProps = {
  links: links[];
};
/**
 * パンくずリストコンポーネント
 * @param links links[]
 * @returns 
 */
const TopicPath: React.FC<TopicPathProps> = ({ links }) => {

  const isWide = UseIsWide();

  const linkStyle = {
    color: colorData['black'],
    fontSize: "14px",
    margin: "2px 20px",
    marginBottom: "5px",
    textDecoration: 'none',
  }

  return (
    <div style={{paddingBottom:10}}>
      {isWide ?
        <Box style={{ margin: "20px 100px 0px 0px" }}>
          <Grid container alignItems='flex-end' justifyContent='flex-first'>
            <Link to={`/`} style={linkStyle}>HOME</Link>
            {links.map((link, index) => (
              <React.Fragment key={index}>
                <div>
                  ＞
                </div>
                <Link to={link.link} style={linkStyle}>{link.pageName}</Link>
              </React.Fragment >
            ))}
          </Grid>
        </Box>
        :
        ''
      }
    </div>

  )
}

export default TopicPath;

