import { Box } from '@mui/material';
import '../../../App.css';
import colorData from '../../../json/color.json';

type props = {
  text?: string | undefined;
}

/**
 * H1テキストコンポーネント
 * @param text string | undefined
 * @returns 
 */
const H1: React.FC<props> = ({ text = '' }) => {

  const backgroundStyle = {
    backgroundColor: colorData['crimson'],
    height: 150,
    color: colorData['white'],
    fontSize: {xs:16, sm:20},
  }

  return (
    <Box sx={{...backgroundStyle, display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
      <h1>
        {text}
      </h1>
    </Box>
  );

}

export default H1;
