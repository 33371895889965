import '../App.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import textData from '../json/text.json';
import colorData from '../json/color.json';
import Header from '../components/menu/organisms/Header';
import Footer from '../components/footer/Footer';
import TopicPath from '../components/common/atoms/TopicPath';
import H1 from '../components/common/atoms/H1';
import ContactText from '../components/contact/molecules/ContacTextt';
import From from '../components/contact/molecules/From';
import BackgroundAnimation from '../components/motion/atoms/BackgroundAnimation';
import { Container } from '@mui/material';

/**
 * お問い合わせページ
 * @returns 
 */
function Contact() {
  const links = [{link:'/Consept', pageName:textData['menu.contact']['ja']}];
  const titleText = textData['menu.contact']['ja'];

  return (
    <div>
      <BackgroundAnimation />
      <HelmetProvider>
        <Helmet>
          <title>{textData['home.title']['ja']}</title>
          <meta name="description" content={textData['concept.title']['ja']} />
        </Helmet>
      </HelmetProvider>

      <header>
        <Header />
      </header>

      <main className="main-content">
        <TopicPath links={links} />
        <H1 text={titleText} />
        <Container style={{background: colorData['white']}}>
          <ContactText />
          <From />
        </Container>
      </main>

      <footer>
        <Footer />
      </footer>

    </div>
  );
}

export default Contact;
