import React from 'react';
import SesContentGroups from './SesContentGroups';
import ContainerContents from '../../common/molecules/ContainerContents';

/**
 * システムエンジニアリングサービス
 * @returns 
 */
const SesContent: React.FC = () => {
  return (
    <ContainerContents children={<SesContentGroups />}/>
  );
};

export default SesContent;
