import { motion, useTransform, useScroll  } from 'framer-motion';

/**
 * 背景アニメーション
 * @returns 
 */
const BackgroundAnimation = () => {
const { scrollYProgress } = useScroll();
const scale = useTransform(scrollYProgress, [0, 1], [0.2, 2]);
const x = useTransform(scrollYProgress, [0, 1], [-100, 100]);
const xReverse = useTransform(scrollYProgress, [0, 1], [100, -100]);
const boxColor = '#EDF9F0';

  return (
    <div className="background-animation-wrapper">
      <div className="box1">
        <motion.div
          className="boxes"
          style={{
            scale,
            background: boxColor,
            x
          }}
        >
          <motion.div
            className="item"
            style={{
              scaleY: scrollYProgress
            }}
          />
        </motion.div>
      </div>
      <div className="box2">
        <motion.div
          className="boxes"
          style={{
            scale,
            background: boxColor,
            x
          }}
        >
          <motion.div
            className="item"
            style={{
              scaleY: scrollYProgress
            }}
          />
        </motion.div>
      </div>
      <div className="box3">
        <motion.div
          className="boxes"
          style={{
            scale,
            background: boxColor,
            x: xReverse
          }}
        >
          <motion.div
            className="item"
            style={{
              scaleY: scrollYProgress
            }}
          />
        </motion.div>
      </div>
      <div className="box4">
        <motion.div
          className="boxes"
          style={{
            scale,
            background: boxColor,
            x: xReverse
          }}
        >
          <motion.div
            className="item"
            style={{
              scaleY: scrollYProgress
            }}
          />
        </motion.div>
      </div>
      <div className="box5">
        <motion.div
          className="boxes"
          style={{
            scale,
            background: boxColor,
            x
          }}
        >
          <motion.div
            className="item"
            style={{
              scaleY: scrollYProgress
            }}
          />
        </motion.div>
      </div>
      <div className="box6">
        <motion.div
          className="boxes"
          style={{
            scale,
            background: boxColor,
            x: xReverse
          }}
        >
          <motion.div
            className="item"
            style={{
              scaleY: scrollYProgress
            }}
          />
        </motion.div>
      </div>
      <div className="box7">
        <motion.div
          className="boxes"
          style={{
            scale,
            background: boxColor,
            x: xReverse
          }}
        >
          <motion.div
            className="item"
            style={{
              scaleY: scrollYProgress
            }}
          />
        </motion.div>
      </div>
      <div className="box8">
        <motion.div
          className="boxes"
          style={{
            scale,
            background: boxColor,
            x
          }}
        >
          <motion.div
            className="item"
            style={{
              scaleY: scrollYProgress
            }}
          />
        </motion.div>
      </div>
      <div className="box9">
        <motion.div
          className="boxes"
          style={{
            scale,
            background: boxColor,
            x
          }}
        >
          <motion.div
            className="item"
            style={{
              scaleY: scrollYProgress
            }}
          />
        </motion.div>
      </div>
      <div className="box10">
        <motion.div
          className="boxes"
          style={{
            scale,
            background: boxColor,
            x: xReverse
          }}
        >
          <motion.div
            className="item"
            style={{
              scaleY: scrollYProgress
            }}
          />
        </motion.div>
      </div>
    </div>
  );
};

export default BackgroundAnimation;
