import React from 'react';
import type {NewsData} from '../../../types/types';
import TypeA from '../template/TypeA';
import TypeB from '../template/TypeB';

interface Props {
  newsData: NewsData;
}

/**
 * テンプレートレンダー
 * @param newsData NewsData
 * @returns 
 */
const DynamicComponentRenderer: React.FC<Props> = ({newsData}) => {
  const componentType = newsData.template;

  switch (componentType) {
    case 'typeA':
      return <TypeA newsData={newsData}/>;
    case 'typeB':
      return <TypeB newsData={newsData}/>;
    default:
      return <></>;
  }
};

export default DynamicComponentRenderer;
