import React from 'react';
import RowButton from '../../common/molecules/RowButton';
import textData from '../../../json/text.json';
import OwnProductsSelection from '../molecules/OwnProductsSelection';
import IconAndText from '../../common/atoms/IconAndText';

/**
 * 自社商品のコンテンツ群
 * @returns 
 */
const OwnProductsContentGroups: React.FC = () => {
  return (
    <div>
      <OwnProductsSelection />
      <RowButton  
      url_left='/logistics' 
      name_left={<IconAndText text={textData['menu.service.logistics']['ja']}/>}
      url_right='/ses' 
      name_right={<IconAndText text={textData['menu.service.development']['ja']}/>}
      />
    </div>
  );
};

export default OwnProductsContentGroups;
