import React from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom';
import colorData from '../../../json/color.json';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import styled from 'styled-components';

type props = {
  url: string;
  name: string;
  fontSize:number;
  blank: number;
}

const StyledLink = styled(Link)`
color: inherit;
text-decoration: none;
transition: background-color 0.3s, color 0.3s;
&:hover {
  background-color: ${colorData['royalblue']};
  color:${colorData['white']};
}
`;

/**
 * 単体メニュー
 * @param url string
 * @param name string
 * @param fontSize number
 * @param blank number
 * @returns 
 */
const SingleMenu: React.FC<props> = ({ url, name, fontSize, blank }) => {

  const iconStyle = {
    fontSize: 16,
  }

  const linkStyle ={
    fontSize: fontSize,
    padding: "10px 25px",
    width: 200,
    borderRadius: 5,
  }

  const button =
    blank === 0 ? (
      <StyledLink
        style={{...linkStyle, textAlign: 'left'}}
        to={url}
      >
        {name}
      </StyledLink>
    ) : (
      <StyledLink
        style={linkStyle}
        color= "inherit"
        to={url}
        target='_blank'
        rel="noopener noreferrer"
      >
        <div style={{ paddingRight: 10, display: 'inline-block' }}>{name}</div>
        <div style={{ display: 'inline-block' }}><OpenInNewIcon style={iconStyle} /></div>
      </StyledLink>
    )

  return (
    <div style={{padding:'15px 0'}}>
      {button}
    </div>
  );
};

export default SingleMenu;
