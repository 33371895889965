import '../../App.css';
import textData from '../../json/text.json';
import colorData from '../../json/color.json';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import HbLeft from '../menu/atoms/HbLeft';
import SingleMenu from '../menu/atoms/SingleMenu';

/**
 * フッター
 * @returns 
 */
function Footer() {
  const background = {
    backgroundColor: colorData['crimson'],
    color: colorData['white'],
  }
  return (
    <Box style={{ paddingTop: 100 }}>
      <Box style={background}>
        <Box style={{paddingTop: 50 }}>
          <Grid container alignItems='center' justifyContent='center' direction='row' >
            <Grid item md={5} lg={4.5} xl={4}>
              <HbLeft />
            </Grid>
            <Grid item md={7} lg={6.5} xl={5}>
              <SingleMenu url='/overview' name={textData['menu.company.overview']['ja']}  fontSize={18} blank={0}/>
              <SingleMenu url='/concept' name={textData['menu.concept']['ja']}  fontSize={18} blank={0}/>
              <SingleMenu url='/access' name={textData['menu.access']['ja']}  fontSize={18} blank={0}/>
              <SingleMenu url='/news' name={textData['menu.news']['ja']}  fontSize={18} blank={0}/>
            </Grid>
          </Grid>
        </Box>
        <Box style={{ padding: 20, textAlign: 'center' }}>
          {textData['copyright']['ja']}
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
