import { Box, Typography } from '@mui/material';
import React from 'react';
import colorData from '../../../json/color.json';

interface ImageLinkProps {
  imageUrl: string;
  title: string;
  linkTo: string;
}

/**
 * 画像リンクコンポーネント
 * @param imageUrl string
 * @param title string
 * @param linkTo string
 * @returns 
 */
const ImageLink: React.FC<ImageLinkProps> = ({ imageUrl, title, linkTo }) => {
  const containerStyle = {
    textAlign: '',
  };

  const imageStyle = {
    maxWidth: '70%',
    width: '600px',
    cursor: 'pointer',
  };

  const textStyle = {
    color: colorData['black'],
    fontSize: '20px',
  };

  return (
    <Box sx={containerStyle}>
      <Typography
        component='a'
        href={linkTo}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          textDecoration: 'none',
        }}
      >
        <img
          src={imageUrl}
          alt={title}
          style={imageStyle}
        />
      </Typography>
      <Typography sx={textStyle}>{title}</Typography>
    </Box>
  );
};

export default ImageLink;
