import '../../../App.css';
import textData from '../../../json/text.json';
import colorData from '../../../json/color.json';
import { Grid, Box } from '@mui/material';

/**
 * お問い合わせテキスト
 * @returns 
 */
function ContactText() {

  const redTextStyle = {
    color: colorData['red'],
  }

  const greenTextStyle = {
    color: colorData['crimson'],
    paddingBottom:100
  }

  const boldGreenTextStyle = {
    color: colorData['crimson'],
    fontSize: 50,
    fontWeight: 'bold'
  }

  const boxStyke={
   padding:'50px 0'
  }


  return (
    <div>
      <Grid container alignItems='start' justifyContent='space-between' direction='row'>
      <Grid item xs={0.5} />
      <Grid item xs={11}>
      <Box style={boxStyke}>
          {textData['contact.text1']['ja']}
          <div>
            {textData['contact.text2']['ja']}
            <span style={redTextStyle}>
              {textData['contact.red.text1']['ja']}
            </span>
            {textData['contact.text3']['ja']}
          </div>
          {textData['contact.text4']['ja']}
        </Box>

        <Box style={greenTextStyle}>
          <Box style={{ fontSize: 30, paddingBottom:20 }}>{textData['contact.green.text1']['ja']}</Box>
          <Box style={{ display: 'flex' }}>
            <Box>
              <Box style={boldGreenTextStyle}>TEL</Box>
              <Box style={boldGreenTextStyle}>{textData['tel']['ja']} </Box>
              <Box style={{ fontSize: 20 }}>{textData['contact.green.text2']['ja']}</Box>
            </Box>
            <Box style={{paddingLeft:100}}>
              <Box style={boldGreenTextStyle}>FAX</Box>
              <Box style={boldGreenTextStyle}>{textData['fax']['ja']} </Box>
              <Box style={{ fontSize: 20 }}>{textData['contact.green.text3']['ja']}</Box>
            </Box>
          </Box>

        </Box>


      </Grid>
       
 </Grid>


    </div>
  );
}

export default ContactText;
