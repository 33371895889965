import React from 'react';
import { Box } from '@mui/material';
import H2 from '../../common/atoms/H2';
import ContentText from '../../common/atoms/ContentText';
import textData from '../../../json/text.json';
import worksData from '../../../json/works.json';
import type {CategorizedData} from '../../../types/types';
import ImageLink from '../atoms/ImageLink';

const categorizedData: CategorizedData = {
  logistics: [],
  energy: [],
  product: [],
  development: [],
};
const works = { works: [...worksData.works] };
works.works.forEach((work, index) => {
  if (work.product !== undefined) {
    categorizedData.product.push({ ...work.product, index });
  }
});
/**
 * 自社商品サービス
 * @returns 
 */
const OwnProductsSelection: React.FC = () => {
  return (
    <Box>
      <ContentText text={textData['ownProducts.text1']['ja']}/>
      <Box sx={{mt:5}}></Box>
      {categorizedData.product.map((items, index) => (
        <Box key={index}>
          <H2 text={items.title.ja} />
          <Box sx={{mt:5}}></Box>
          <ContentText text={items.text.ja[0]} />
          <ImageLink imageUrl={items.image[0]} title={items.title.ja} linkTo={items.url} text={items.text.ja[1]}/>
        </Box>
      ))}
    <Box sx={{mt:10}}></Box>
    </Box>
  );
};

export default OwnProductsSelection;
