import React from 'react';
import type {WorkEntry} from '../../../types/types';
import TypeA from '../template/TypeA';

interface Props {
  workEntry: WorkEntry;
}

/**
 * テンプレートレンダー
 * @param workEntry WorkEntry
 * @returns 
 */
const DynamicComponentRenderer: React.FC<Props> = ({workEntry}) => {
  const componentType = workEntry.template;

  switch (componentType) {
    case 'typeA':
      return <TypeA workEntry={workEntry}/>;
    default:
      return <></>;
  }
};

export default DynamicComponentRenderer;
