import '../../../App.css';
import { motion } from 'framer-motion';

/**
 * 円モーション
 * @returns 
 */
function Circle() {

  const draw = {
    hidden: { pathLength: 0, opacity: 0, rotate: 0 },
    visible: () => {
      return {
        pathLength: 0.632,
        opacity: 1,
        rotate: 180,
        transition: {
          pathLength: { delay:5, type: "linear", duration: 2, bounce: 0 },
          opacity: { delay:5, duration: 0.01 },
        }
      };
    }
  };

  return (
    <div>
      <motion.svg
        width="500px"
        height="500px"
        viewBox="0 0 50% 50%"
        initial="hidden"
        animate="visible"
      >
        <motion.circle
          cx="50%"
          cy="50%"
          r="125"
          stroke="#fc4d27"
          variants={draw}
          custom={0}
        />
      </motion.svg>
    </div>

  );
}

export default Circle;
