import React from 'react';
import { Box } from '@mui/material';
import ImageHoverSwitch from '../atoms/ImageHoverSwitch';
import AccordionSection from './AccordionSection';
import type { President } from '../../../types/types';

/**
 * 社長紹介
 * @param initialImage string
 * @param hoverImage string
 * @returns 
 */
const PresidentIntroductionSection: React.FC<President> = ({ initialImage, hoverImage, introductionText }) => {

  return (
    <Box sx={{mt:5}}>
      <ImageHoverSwitch initialImage={initialImage} hoverImage={hoverImage} />
      <Box sx={{mt:5}}></Box>
        <AccordionSection
          title={introductionText.title}  
          career={introductionText.career}
          hobby={introductionText.hobby}
          introduction={introductionText.introduction}
          message={introductionText.message}
        />
    </Box>
  );
};

export default PresidentIntroductionSection;
