import React from 'react';
import ResearchSelection from '../molecules/ResearchSelection';

/**
 * 研究開発のコンテンツ群
 * @returns 
 */
const ResearchContentGroups: React.FC = () => {
  return (
    <div>
      <ResearchSelection />
    </div>
  );
};

export default ResearchContentGroups;
