import React from 'react';
import { Box } from '@mui/material';
import H2 from '../../common/atoms/H2';
import ContentText from '../../common/atoms/ContentText';
import textData from '../../../json/text.json';
import InsertImage from '../../common/atoms/InsertImage';
import Contenttitle from '../atoms/Contenttitle';
import CaseSlider from '../atoms/CaseSlider';

/**
 * SESサービス
 * @returns 
 */
const SesSelection: React.FC = () => {
  const achievementCase = textData['ses.3.case'];
  return (
    <Box>
      <ContentText text={textData['ses.text1']['ja']}/>
      <Box sx={{mt:5}}></Box>
      <H2 text={textData['ses.subtitle2']['ja']}/>
      <Box sx={{mt:5}}></Box>
      <InsertImage imageUrl={'/images/illust/service_dispatch_img05.jpg'} title=''/>
      <ContentText text={textData['ses.text2']['ja']}/>
      <Contenttitle text={'開発実績'}/>
      <CaseSlider achievementCase={achievementCase}/>
      <Box sx={{mt:5}}></Box>
      <H2 text={textData['ses.subtitle3']['ja']}/>
      <Box sx={{mt:5}}></Box>
      <ContentText text={textData['ses.text3']['ja']}/>
      <Box sx={{mt:5}}></Box>
      <H2 text={textData['ses.subtitle4']['ja']}/>
      <Box sx={{mt:5}}></Box>
      <ContentText text={textData['ses.text4']['ja']}/>
      <Box sx={{mt:5}}></Box>
    <Box sx={{mt:10}}></Box>
    </Box>
  );
};

export default SesSelection;
