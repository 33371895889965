import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import colorData from '../../../json/color.json';
import useWindowSize from '../../../hooks/useWindowsSize';

interface Props {
  title: string | undefined;
  linkTo: string;
  before: boolean;
}

/**
 * 記事リンクコンポーネント
 * @param title string
 * @param linkTo string
 * @param before boolean
 * @returns 
 */
const NewsNavigation: React.FC<Props> = ({ title, linkTo, before = true }) => {
  const [width] = useWindowSize();
  const [isWide, setisWide] = useState(true);

  useEffect(() => {
    if (width === 0) {
        return setisWide(true);
    }
    if (width < 920) {
        return setisWide(false);
    }
    return setisWide(true);
}, [width]);

  const linkStyle = {
    textAlign: 'center',
    textDecoration: 'none',
    color: colorData['black'],
    padding: '20px 30px',
    border: `solid 5px ${colorData['royalblue']}`,
    backgroundColor: colorData['white'],
    borderRadius: 15,
    '&:hover': {
      backgroundColor: colorData['royalblue'],
      color: colorData['white'],
    }
  };
  const continerStyle = {
    pt: 10,
  };

  const text = before ? '前の記事：' : '次の記事：';
  const arrow = before ? '<<' : '>>';
  return (
    <Box sx={continerStyle}>
      <Typography
        component='a'
        href={linkTo}
        sx={linkStyle}
      >
        {isWide ? text + title : arrow}        
      </Typography>
    </Box>
  );
};

export default NewsNavigation;
