import '../App.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import textData from '../json/text.json';
import Header from '../components/menu/organisms/Header';
import Footer from '../components/footer/Footer';
import TopContent from '../components/top/organisms/TopContent';
import MVmotion from '../components/motion/organisms/MVmotion';
import ScrollToTopButton from '../components/common/atoms/ScrollToTopbutton';
import ScrollDownButton from '../components/top/atoms/ScrollDownButton';

/**
 * トップページ
 * @returns 
 */
function Home() {

  return (
    <div>
      <ScrollToTopButton />
      <HelmetProvider>
        <Helmet>
          <title>{textData['home.title']['ja']}</title>
          <meta name="description" content= {textData['common.description']['ja']} />
        </Helmet>
      </HelmetProvider>

      <header>
        <Header />
      </header>

      <main className="main-content">
        <MVmotion/>
        <ScrollDownButton/>
        <TopContent />
      </main>

      <footer>
        <Footer />
      </footer>

    </div>
  );
}

export default Home;
