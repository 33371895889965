import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Box } from '@mui/material';
import useWindowSize from '../../../hooks/useWindowsSize';

type Props = {
  images: string[],
};

const ImageRotator: React.FC<Props> = ({ images }) => {
  const [width] = useWindowSize();
  const [isWide, setisWide] = useState(true);

  useEffect(() => {
    if (width === 0) {
        return setisWide(true);
    }
    if (width < 920) {
        return setisWide(false);
    }
    return setisWide(true);
}, [width]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);

    return () => clearInterval(intervalId);
  }, [images.length]);

  return (
    <Box>
      {isWide ?
        <>
        <AnimatePresence>
          {images.map((image, index) => (
            <motion.img
              key={index}
              src={image}
              alt={`rotating image ${index}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: index === currentImageIndex ? 1 : 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              style={{ position: 'absolute', top: '10%', left: '25%', width: '500px' }}
            />
          ))}
        </AnimatePresence>
        </> :
        <>
        <AnimatePresence>
          {images.map((image, index) => (
            <motion.img
              key={index}
              src={image}
              alt={`rotating image ${index}`}
              initial={{ opacity: 0 }}
              animate={{ opacity: index === currentImageIndex ? 1 : 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              style={{ position: 'absolute', top: '10%', left: '5%', width: '200px' }}
            />
          ))}
        </AnimatePresence>
        </>
      }
    </Box>
  );
};

export default ImageRotator;
