import React from 'react';
import { motion } from 'framer-motion';

type Props = {
  scaleX?: number,
};

const Line: React.FC<Props> = ({ scaleX = 1 }) => {
  const boxInitialStyle = {
    width: 5,
    height: 5,
    borderRadius: '50%',
    backgroundColor: "#fc4d27",
  };

  const boxAnimateStyle = {
    width: 120,
    height: 10,
    borderRadius: '0%',
    originX: 0,
    scaleX: scaleX,
  };

  return (
    <div>
      <motion.div
        style={{ ...boxInitialStyle }}
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1, ...boxAnimateStyle }}
        transition={{
          opacity: { duration: 0.4, delay: 3 },
          scale: { duration: 0.4, delay: 3 },
          width: { duration: 0.4, delay: 4 },
          borderRadius: { duration: 0.4, delay: 4 },
          ease: 'easeInOut',
        }}
      />
    </div>
  );
}

export default Line;
