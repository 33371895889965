import React from 'react';
import InformationSection from '../molecules/InformationSection';

/**
 * 企業概要のコンテンツ群
 * @returns 
 */
const OverviewContentGroups: React.FC = () => {
  return (
    <div>
      <InformationSection />
    </div>
  );
};

export default OverviewContentGroups;
