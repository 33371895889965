import '../../../App.css';
import Logo from '../atoms/Logo';
import { UseIsWide } from '../../common/atoms/UseIsWide';
import Container from '@mui/material/Container';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Grid";
import PcMenu from '../molecules/PcMenu';
import SpMenu from '../molecules/SpMenu';

/**
 * ヘッダー
 * @returns 
 */
function Header() {

  const isWide = UseIsWide();

  const headerStyle: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 1000,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
  };
  return (
    <>
    <div style={headerStyle}>
      {isWide ?
        <Container maxWidth="xl">
          <Grid container alignItems='flex-start' justifyContent='space-between' direction='row' >
            <div>
              <Logo />
            </div>
            <div>
              <PcMenu />
            </div>
          </Grid>
        </Container>
        :
        <Container maxWidth="xl">
          <Grid container alignItems='flex-start' justifyContent='space-between' direction='row' >
            <div>
              <Logo />
            </div>
            <div>
              <SpMenu />
            </div>
          </Grid>
        </Container>
      }
    </div>
    <Box sx={{ mb:10 }}/>
    </>
  );

}

export default Header;


