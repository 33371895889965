import { Box, Typography } from '@mui/material';
import React from 'react';

interface ImageLinkProps {
  imageUrl: string;
  title: string;
  linkTo?: string;
  text: string;
}

/**
 * 画像リンク
 * @param imageUrl string
 * @param title string
 * @param linkTo string
 * @param text string
 * @returns 
 */
const ImageLink: React.FC<ImageLinkProps> = ({ imageUrl, title, linkTo = "/", text }) => {
  const containerStyle = {
    textAlign: 'center',
    position: 'relative',
    paddingTop: '50px',
    '& img': {
      maxWidth: '100%',
      width: 'auto',
      height: 'auto',
      cursor: 'pointer',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      transition: 'transform 0.2s ease, box-shadow 0.2s ease',
      '&:hover': {
        boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)',
      },
      '&:active': {
        transform: 'translateY(4px)',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
      }
    }
  };

  const imageStyle = {
    maxWidth: '50%',
    cursor: 'pointer',
  };

  const textStyle = {
    padding: '10px',
    fontSize: '20px',
  }

  return (
    <Box sx={containerStyle}>
      <Box component='span' sx={textStyle}>
        {text}
      </Box>
      <Typography
        component='a'
        href={linkTo}
        sx={{
          textDecoration: 'none',
        }}
        target='_blank'
      >
        <img
          src={imageUrl}
          alt={title}
          style={imageStyle}
        />
      </Typography>
    </Box>
  );
};

export default ImageLink;
