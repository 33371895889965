import React from 'react';
import type {WorkEntry} from '../../../types/types';
import DynamicComponentRenderer from '../molecules/DynamicComponentRenderer';
import ContainerContents from '../../common/molecules/ContainerContents';

interface Props {
  workEntry: WorkEntry;
}

/**
 * コンテンツ群
 * @param workEntry
 * @returns 
 */
const WorksAchievementsContent: React.FC<Props> = ({workEntry}) => {
  return (
    <ContainerContents children={<DynamicComponentRenderer workEntry={workEntry}/>}/>
  );
};

export default WorksAchievementsContent;
