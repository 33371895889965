import React from 'react';
import { Box } from '@mui/material';
import H2 from '../../common/atoms/H2';
import InsertImage from '../../common/atoms/InsertImage';
import textData from '../../../json/text.json';
import ContentText from '../../common/atoms/ContentText';
import LargerText from '../atoms/LargerText';
import VisionTitle from '../atoms/VisionTitle';
import VisionText from '../atoms/VisionText';

/**
 * 経営理念
 * @returns 
 */
const PhilosophySection: React.FC = () => {
  return (
    <Box>
        <H2 text={textData['philosophy.subtitle1']['ja']}/>
        <Box sx={{mt:5}}></Box>
        <InsertImage imageUrl='/images/photo/meeting_1.png' title={textData['concept.subtitle1']['ja']} />
        <Box sx={{mt:5}}></Box>
        <ContentText text={textData['philosophy.text1']['ja']}/>
        <Box sx={{mt:10}}></Box>
        <H2 text={textData['philosophy.subtitle2']['ja']}/>
        <Box sx={{mt:10}}></Box>
        <LargerText text={textData['philosophy.subtitle2_2']['ja']}/>
        <Box sx={{mt:10}}></Box>
        <ContentText text={textData['philosophy.text2']['ja']}/>
        <Box sx={{mt:10}}></Box>
        <H2 text={textData['philosophy.subtitle3']['ja']}/>
        <Box sx={{mt:5}}></Box>
        <VisionTitle text={textData['philosophy.subtitle3_2']['ja']}/>
        <Box sx={{mt:5}}></Box>
        <VisionText firstText={textData['philosophy.text3']['ja']} secondText={textData['philosophy.text3_2']['ja']} thirdText={textData['philosophy.text3_3']['ja']}/>
        <Box sx={{mt:10}}></Box>
        <H2 text={textData['philosophy.subtitle4']['ja']}/>
        <Box sx={{mt:5}}></Box>
        <VisionText firstText={textData['philosophy.subtitle4_2']['ja']} secondText={textData['philosophy.subtitle4_3']['ja']} thirdText={textData['philosophy.subtitle4_4']['ja']}/>
        <Box sx={{mt:5}}></Box>
        <ContentText text={textData['philosophy.text4']['ja']}/>
        <Box sx={{mt:10}}></Box>
        <H2 text={textData['philosophy.subtitle5']['ja']}/>
        <Box sx={{mt:5}}></Box>
        <ContentText text={textData['philosophy.subtitle5_2']['ja']}/>
        <Box sx={{mt:5}}></Box>
        <ContentText text={textData['philosophy.text5']['ja']}/>
        <Box sx={{mt:10}}></Box>
    </Box>
  );
};

export default PhilosophySection;
