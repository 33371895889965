import React from 'react';
import { Box } from '@mui/material';
import H2 from '../../common/atoms/H2';
import ContentText from '../../common/atoms/ContentText';
import textData from '../../../json/text.json';
import SkillsComponent from './SkillsComponent';

/**
 * スキル
 * @returns 
 */
const SkillSelection: React.FC = () => {
  return (
    <Box>
      <H2 text={textData['ses.subtitle5']['ja']}/>
      <Box sx={{mt:5}}></Box>
      <ContentText text={textData['ses.text5']['ja']}/>
      <Box sx={{mt:5}}></Box>
      <SkillsComponent />
    <Box sx={{mt:10}}></Box>
    </Box>
  );
};

export default SkillSelection;
