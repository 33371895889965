import '../../../App.css';
import textData from '../../../json/text.json';
import SingleMenu from '../atoms/SingleMenu';

/**
 * PC版メニュー
 * @returns 
 */
function PcMenu() {

  const global_menu =  'global_menu';

  return (
    <div style={{ paddingTop: 20 }}>
      <div className={global_menu}>
        <SingleMenu url='/overview' name={textData['menu.company.overview']['ja']}  fontSize={16} blank={0}/>
        <SingleMenu url='/concept' name={textData['menu.concept']['ja']}  fontSize={16} blank={0}/>
        <SingleMenu url='/access' name={textData['menu.access']['ja']}  fontSize={16} blank={0}/>
        <SingleMenu url='/news' name={textData['menu.news']['ja']}  fontSize={16} blank={0}/>
      </div>
    </div>
  );

}

export default PcMenu;
