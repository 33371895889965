import React from 'react';
import { Box, Typography } from '@mui/material';
import colorData from '../../../json/color.json';
import SkillItem from './SkillItem';

interface Props {
  title: string;
  items: string[];
  text: string;
}

/**
 * スキル一覧表示コンポーネント
 * @param title string
 * @param items string[]
 * @param text string
 * @returns 
 */
const SkillList: React.FC<Props> = ({title, items, text}) => {
  const containerStyle = {
    padding: '30px',
    margin: '10px',
    borderRadius: '20px',
    backgroundColor: colorData['royalblue'],
    width: '500px',
    height: '420px'
  };

  const caseText = {
    padding: '5px 15px 5px 15px',
    margin: '5px',
    backgroundColor: colorData['crimson'],
    color: colorData['white'],
    width: '100px'
  }

  const boxStyle = {
    backgroundColor: colorData['white'],
  }

  const titleBoxStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colorData['crimson'],
    height: '100px'
  }

  const titleStyle = {
    color: colorData['crimson'],
    fontSize: '24px',
    whiteSpace: 'pre-wrap'
  }

  const skillBoxStyle = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '5px 10px 5px 10px'
  }

  const textBoxStyle = {
    textAlign: 'left',
    height: '100px',
    padding: '5px 10px 5px 10px',
    whiteSpace: 'pre-wrap'
  }

    return (
      <Box sx={containerStyle}>
        <Typography sx={caseText}>CASE</Typography>
        <Box sx={boxStyle}>
          <Box sx={titleBoxStyle}>
            <Typography component={'h1'} sx={titleStyle}>{title}</Typography>
          </Box>          
          <Box sx={skillBoxStyle}>
            {items.map((item, index)=>(
              <Box key={index}>
                <SkillItem text={item}/>
              </Box>
            ))}            
          </Box>
          <Box sx={textBoxStyle}>
            <Typography>{text}</Typography>
          </Box>
        </Box>
      </Box>
    );
  };
  
  export default SkillList;