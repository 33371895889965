import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Home from './pages/Home';
import Concept from './pages/company/Concept';
import Philosophy from './pages/company/Philosophy';
import Overview from './pages/company/Overview';
import Staff from './pages/company/Staff';
import Contact from './pages/Contact';
import Demo from './pages/Demo';
import Demo2 from './pages/Demo2';
import Logistics from './pages/service/Logistics';
import OwnProducts from './pages/service/OwnProducts';
import Ses from './pages/service/Ses';
import Works from './pages/Works';
import News from './pages/News';
import Research from './pages/Research';
import ScrollToTop from './components/common/atoms/ScrollToTop';
import WorksListTemplate from './pages/works/WorksListTemplate';
import NewsTemplate from './pages/News/NewsTemplate';
import Access from './pages/company/Access';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop /> 
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/concept" element={<Concept />} />
        <Route path="/philosophy" element={<Philosophy />} />
        <Route path="/overview" element={<Overview />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/staff" element={<Staff />} />
        <Route path="/logistics" element={<Logistics />} />
        <Route path="/ownProducts" element={<OwnProducts />} />
        <Route path="/ses" element={<Ses />} />
        <Route path="/works" element={<Works />} />
        <Route path="/news" element={<News />} />
        <Route path="/research" element={<Research />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/demo2" element={<Demo2 />} />
        <Route path="/access" element={<Access />} />
        {/* 実績ページ */}
        <Route path="/works/logistics/:index" element={<WorksListTemplate />} />
        <Route path="/works/product/:index" element={<WorksListTemplate />} />
        <Route path="/works/energy/:index" element={<WorksListTemplate />} />
        <Route path="/works/development/:index" element={<WorksListTemplate />} />
        {/* 新着情報ページ */}
        <Route path="/news/:index" element={<NewsTemplate />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
