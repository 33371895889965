import React from 'react';
import worksData from '../../../json/works.json';
import WorksContentGroups from './WorksContentGroups';
import type {Achievement, WorkEntry} from '../../../types/types';
import ContainerContents from '../../common/molecules/ContainerContents';

const works = { works: [...worksData.works] };
const achievements: Achievement[] = works.works.flatMap((work, index) => {
  return Object.entries(work).map(([key, value]) => {
    const workEntry = value as WorkEntry;
    return {
      title: workEntry.title.ja,
      imageUrl: workEntry.image[0],
      linkTo: `/works/${key}/${index}`
    };
  });
});
/**
 * 実績一覧
 * @returns 
 */
const WorksContent: React.FC = () => {
  return (
    <ContainerContents children={<WorksContentGroups achievements={achievements}/>}/>
  );
};

export default WorksContent;
