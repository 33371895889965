import { Box, Typography } from '@mui/material';
import React from 'react';
import colorData from '../../../json/color.json';

interface ImageLinkProps {
  title: string;
  linkTo: string;
}

/**
 * リンク
 * @param title string
 * @param linkTo string
 * @returns 
 */
const LinkButton: React.FC<ImageLinkProps> = ({ title, linkTo }) => {
  const containerStyle = {
    textAlign: 'center',
    position: 'relative',
  };

  const textStyle = {
    backgroundColor: colorData['royalblue'],
    color: colorData['white'],
    borderRadius: '10px',
    fontSize: {xs: '18px', md: '24px', xl: '28px'},
    fontWeght: 'bolder',
    curser: 'pointer',
    padding: '5px 30px',
    transition: 'background-color 0.3s, color 0.3s',
    boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: colorData['crimson'],
      boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.2)',
    },
  };

  return (
    <Box sx={containerStyle}>
      <Typography
        component='a'
        href={linkTo}
        sx={{
          textDecoration: 'none',
        }}
      >
        <Typography sx={textStyle}>{title} ▶︎</Typography>
      </Typography>
    </Box>
  );
};

export default LinkButton;
