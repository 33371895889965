import React from 'react';
import { Box, Fab } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import colorData from '../../../json/color.json';

const ScrollDownButton: React.FC = () => {
  const scrollDown = () => {
    window.scrollBy({
      top: window.innerHeight,
      behavior: 'smooth',
    });
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Fab
        aria-label="scroll down"
        style={{
          color: colorData['royalblue'],
          bottom: 20,
          right: 20,
          display: 'block',
        }}
        onClick={scrollDown}
      >
        <KeyboardArrowDownIcon />
      </Fab>
    </Box>
  );
};

export default ScrollDownButton;
