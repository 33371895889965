import '../../../App.css';
import colorData from '../../../json/color.json';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

type props = {
  text?: string | undefined;
}

/**
 * 矢印アイコン＋テキストコンポーネント
 * @param text string | undefined
 * @returns 
 */
const IconAndText: React.FC<props> = ({ text = '' }) => {

  const iconStyle = {
    color: colorData['black'],
    margin: 10,
  }

  return (
    <>
      <span style={iconStyle}><ArrowForwardIcon /></span>
      <span style={{verticalAlign: 'top'}}>{text}</span>
    </>
  );

}

export default IconAndText;
