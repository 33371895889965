import React from 'react';
import { Box } from '@mui/material';
import ContentText from '../atoms/ContentText';
import textData from '../../../json/text.json';
import colorData from '../../../json/color.json';
import ImageWithTitle from '../atoms/ImageWithTitle';
import type {NewsAchievement} from '../../../types/types';

interface Props {
  newsList: NewsAchievement[];
}

/**
 * お知らせ情報
 * @returns 
 */
const RecordSelection: React.FC<Props> = ({newsList}) => {

  const boxStyle = {
    margin: '20px 10px 20px 10px',
    backgroundColor: colorData['white'],
    padding: '10px',
    width: '500px'
  };

  return (
    <Box>
      <ContentText text={textData['news.text']['ja']} textAlign={'center'}/>
      <Box sx={{mt:10}}></Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: { xs:'column', lg: 'row' },
          justifyContent: "center",
          mt: 0,
          alignItems: 'center',
          width: '100%',
        }}
      >
        {newsList.map((items, index) =>(
          <Box key={index} sx={boxStyle}>
            <ImageWithTitle imageUrl={items.imageUrl} title={items.title} linkTo={items.linkTo} contentsCount={2} date={items.date}/>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default RecordSelection;
