import React from 'react';
import LogisticsContentGroups from './LogisticsContentGroups';
import ContainerContents from '../../common/molecules/ContainerContents';

/**
 * 物流コンサルティング
 * @returns 
 */
const LogisticsContent: React.FC = () => {
  return (
    <ContainerContents children={<LogisticsContentGroups />}/>
  );
};

export default LogisticsContent;
