import React from 'react';
import { Box } from '@mui/material';
import LineText from '../atoms/LineText';
import textData from '../../../json/text.json';
import ContentText from '../../common/atoms/ContentText';

interface props {
  career: string[];
  hobby: string;
  introduction: string;
  message: string;
}
/**
 * インタビュー
 * @param career string
 * @param hobby string
 * @param introduction string
 * @param message string
 * @returns 
 */
const IntroductionSection: React.FC<props> = ({ career, hobby, introduction, message }) => {

  return (
    <Box>
      <LineText text={textData['staff.career']['ja']} />
      <ContentText text={introduction} />
      {career.map((text, index) => (
        <ContentText text={text} key={index}/>
      ))}
      <Box sx={{mt:5}}></Box>
      <LineText text={textData['staff.hobby']['ja']} />
      <ContentText text={hobby} />
      <Box sx={{mt:5}}></Box>
      <LineText text={textData['staff.message']['ja']} />
      <ContentText text={message} />
      <Box sx={{mt:5}}></Box>
    </Box>
  );
};

export default IntroductionSection;
