import React from 'react';
import Slider from 'react-slick';
import { Box, Typography } from '@mui/material';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import type { Achievement } from '../../../types/types';
import colorData from '../../../json/color.json';

interface AchievementsSliderProps {
  achievements: Achievement[];
}

/**
 * 実績スライドコンポーネント
 * @param achievements Achievement[]
 * @returns 
 */
const AchievementsSlider: React.FC<AchievementsSliderProps> = ({ achievements }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    responsive: [
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  const boxStyle = {
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    '& img': {
      maxWidth: '100%',
      width: 'auto',
      height: 'auto',
      cursor: 'pointer',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      transition: 'transform 0.2s ease, box-shadow 0.2s ease',
      '&:hover': {
        boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2)',
      },
      '&:active': {
        transform: 'translateY(4px)',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
      }
    }
  }
  const linkStyle = {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textDecoration: 'none',
    color: colorData['black']
  }
  const titleStyle = {
    textAlign: 'center',
    paddingTop: '20px'
  }
  return (
    <Slider {...settings}>
      {achievements.map((achievement, index) => (
        <Box key={index} sx={boxStyle}>
          <Typography component="a" href={achievement.linkTo} sx={linkStyle}>
            <img src={achievement.imageUrl} alt={achievement.title} style={{ width: '70%', height: 'auto' }} />
            <Typography sx={titleStyle}>{achievement.title}</Typography>
          </Typography>
        </Box>
      ))}
    </Slider>
  );
};

export default AchievementsSlider;
