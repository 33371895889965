import React from 'react';
import { Box, Grid } from '@mui/material';
import colorData from '../../../json/color.json';

interface category {
  webOpenSystem: {
    title: {
      ja: string;
      en: string;
    };
    item: string[]
  };
  controlEmbeddedSystem: {
    title: {
      ja: string;
      en: string;
    };
    item: string[]
  };
}

interface Props {
  list: category;
}

/**
 * スキル一覧表示コンポーネント(複数)
 * @param list []
 * @returns 
 */
const SkillMultiList: React.FC<Props> = ({list}) => {
  const gridStyle = {
    textAlign: 'left',
    padding: '10px',
    margin: '2px',
    '&:hover': {
      backgroundColor: colorData['royalblue'],
    },
    borderBottom: `solid 1px ${colorData['gray']}`
  };
  const titleGridStyle = {
    textAlign: 'left',
    padding: '10px',
    marginTop: '20px',
    borderBottom: `solid 3px ${colorData['gray']}`
  };
    return (
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={12} md={12} sx={titleGridStyle}>{list.webOpenSystem.title.ja}</Grid>
          {list.webOpenSystem.item.map((item, index) => (
            <Grid item xs={12} md={3.9} key={index} sx={gridStyle}>
              {item}
            </Grid>
          ))}
          <Grid item xs={12} md={12} sx={titleGridStyle}>{list.controlEmbeddedSystem.title.ja}</Grid>
          {list.controlEmbeddedSystem.item.map((item, index) => (
            <Grid item xs={12} md={3.9} key={index} sx={gridStyle}>
              {item}
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };
  
  export default SkillMultiList;