import React from 'react';
import PresidentIntroductionSection from '../molecules/PresidentIntroductionSection';
import type { IntroducingItems } from '../../../types/types';

/**
 * 社員紹介のコンテンツ群
 * @param initialImage string
 * @param hoverImage string
 * @returns 
 */
const StaffContentGroups: React.FC<IntroducingItems> = ({ president }) => {
  return (
    <div>
      <PresidentIntroductionSection initialImage={president.initialImage} hoverImage={president.hoverImage} introductionText={president.introductionText} />
    </div>
  );
};

export default StaffContentGroups;
