import React from 'react';
import { Box } from '@mui/material';
import H2 from '../../common/atoms/H2';
import InsertImage from '../../common/atoms/InsertImage';
import ContentText from '../../common/atoms/ContentText';
import textData from '../../../json/text.json';
import worksData from '../../../json/works.json';
import type {Achievement, CategorizedData} from '../../../types/types';
import AchievementsSlider from '../../common/atoms/AchievementsSlider';

const categorizedData: CategorizedData = {
  logistics: [],
  energy: [],
  product: [],
  development: [],
};
const works = { works: [...worksData.works] };
works.works.forEach((work, index) => {
  if (work.logistics !== undefined) {
    categorizedData.logistics.push({ ...work.logistics, index });
  }
});
const logisticsAchievements: Achievement[] = categorizedData.logistics.map(items => ({
  title: items.title.ja,
  imageUrl: items.image[0],
  linkTo: `/works/logistics/${items.index}`
}));
/**
 * 物流コンサルティング
 * @returns 
 */
const LogisticsSection: React.FC = () => {
  return (
    <Box>
      <H2 text={textData['logistics.subtitle1']['ja']}/>
      <Box sx={{mt:5}}></Box>
      <InsertImage imageUrl='/images/photo/meeting_2.png' title={textData['logistics.subtitle1']['ja']} />
      <Box sx={{mt:5}}></Box>
      <ContentText text={textData['logistics.text1']['ja']}/>
      <Box sx={{mt:5}}></Box>
      <InsertImage imageUrl='/images/illust/consulting_flow.png' title={textData['logistics.subtitle1']['ja']} />
      <Box sx={{mt:5}}></Box>
      <H2 text={textData['logistics.subtitle2']['ja']}/>
      <Box sx={{mt:5}}></Box>
      <ContentText text={textData['logistics.text2']['ja']}/>
      <Box sx={{mt:5}}></Box>
      <InsertImage imageUrl='/images/illust/logistics_solution_case.png' title={textData['logistics.subtitle2']['ja']} />
      <Box sx={{mt:10}}></Box>
      <AchievementsSlider achievements={logisticsAchievements}/>
      <Box sx={{mt:5}}></Box>
    </Box>
  );
};

export default LogisticsSection;
