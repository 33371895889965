import { Button, ThemeProvider } from '@mui/material';
import React from 'react';
import colorData from '../../../json/color.json';
import { createTheme } from '@mui/material/styles';

type Props = {
  targetId: string;
  text: string;
};

const theme = createTheme({
  palette: {
    primary: {
      main: colorData['royalblue'],
    },
    secondary: {
      main: colorData['crimson'],
    },
  },
});

/**
 * リンクボタンコンポーネント
 * @param targetId string
 * @param text string
 * @returns 
 */
const LinkButton: React.FC<Props> = ({ targetId, text }) => {
  return (
    <ThemeProvider theme={theme}>
      <Button variant="contained" component="a" href={`${targetId}`}>
        {text}
      </Button>
    </ThemeProvider>
  );
};

export default LinkButton;
