import { useEffect, useState } from 'react';
import '../../../App.css';
import { motion } from 'framer-motion';

/**
 * 台形モーション2D
 * @returns 
 */
function Rhombus2D() {

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(true);
    }, 9000); 

    return () => clearTimeout(timeoutId);
  }, []);

  const BoxStyle = {
    width: 500,
    height: 500,
  }

  const frontStyle = {
    width: 150,
    height: 140,
    backgroundColor: '#106520',
    transform: 'scaleX(2) skewX(-27deg)',
  }

  return (
    <div>

      {isVisible && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, ease: 'easeOut' }}
          style={{ ...BoxStyle, position: 'absolute' }}
        >
          <div style={{ ...frontStyle, position: 'absolute', top: '87%', left: '24.5%' }} />
        </motion.div>
      )}
    </div>

  );
}


export default Rhombus2D;
