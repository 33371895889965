import MVtext from '../molecules/MVtext';
import { Box } from '@mui/material';
import ImageRotator from '../atoms/ImageRotator';

const images = ['/images/photo/Image1.png', '/images/photo/Image2.png', '/images/photo/Image3.png'];

/**
 * メインビジュアルモーション
 * @returns 
 */
const MVmotion = () => {
  return (
    <Box sx={{height: '80vh', position: 'relative'}}>
      <ImageRotator images={images}/>
      <MVtext/>
    </Box>
  );
};

export default MVmotion;
