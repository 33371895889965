import '../../../App.css';

type props = {
  mapSrc: string;
  title: string;
}

/**
 * マップ表示コンポーネント
 * @param mapSrc string
 * @param title string
 * @returns 
 */
const AccessMap: React.FC<props> = ({ mapSrc, title }) => {
  return (
    <iframe 
      src={mapSrc}
      width="100%"
      height="450"
      style={{ border: 0 }}
      allowFullScreen={true}
      loading="lazy"
      title={title}>
    </iframe>
  );

}

export default AccessMap;
