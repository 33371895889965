import { Box, Typography } from '@mui/material';
import '../../../App.css';
import colorData from '../../../json/color.json';

type props = {
  text: string;
  textAlign: 'left' | 'right' | 'center';
}

/**
 * 大きいタイトルコンポーネント
 * @param text string
 * @param textAlign 'left' | 'right' | 'center'
 * @returns 
 */
const LargerTitle: React.FC<props> = ({ text, textAlign }) => {
  
  const textStyle ={
    color:colorData['black'],
    fontSize:30,
    textAlign: {textAlign},
    whiteSpace: 'pre-wrap',
    fontWeight: 'bold'
  }
 

  return (
    <Box style={{paddingTop:10}}>
      <Typography variant="h2" sx={textStyle}>
       {text}
      </Typography>
    </Box>
  );

}

export default LargerTitle;
