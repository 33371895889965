import React from 'react';
import '../../../App.css';
import { UseIsWide } from '../../common/atoms/UseIsWide';
import { Link } from 'react-router-dom';
import colorData from '../../../json/color.json';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ListItemButton from '@mui/material/ListItemButton';

type props = {
  url: string;
  name: string;
  fontSize:number;
  blank: number;
}

/**
 * スマホ版単体メニュー
 * @param url string
 * @param name string
 * @param fontSize number
 * @param blank number
 * @returns 
 */
const SpSingleMenu: React.FC<props> = ({ url, name, fontSize, blank }) => {

  const isWide = UseIsWide();

  const iconStyle = {
    fontSize: 19,
  }

  const commonButton = {
    fontSize: fontSize,
    padding: "15px 25px",
    width:isWide ? 200 :"100%",
    borderRadius: isWide ? 5 : 0,
  }

  const button =
    blank === 0 ? (
      <ListItemButton
        style={commonButton}
        sx={{
          "&:hover": {
            backgroundColor: colorData['royalblue'],
            color: colorData['white'],
          },
        }}
        component={Link}
        to={url}
      >
        {name}
      </ListItemButton>
    ) : (
      <ListItemButton
        style={commonButton}
        color= "inherit"
        sx={{
          "&:hover": {
            backgroundColor: colorData['royalblue'],
            color: colorData['white'],
          },
        }}
        component={Link}
        to={url}
        target='_blank'
        rel="noopener noreferrer"
      >
        <div style={{ paddingRight: 10, display: 'inline-block' }}>{name}</div>
        <div style={{ display: 'inline-block' }}><OpenInNewIcon style={iconStyle} /></div>
      </ListItemButton>
    )
  return (
    <div>
      {button}
    </div>
  );
};

export default SpSingleMenu;
