import '../../App.css';
import { useState, useEffect, useMemo } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import textData from '../../json/text.json';
import newsData from '../../json/news.json';
import Header from '../../components/menu/organisms/Header';
import Footer from  '../../components/footer/Footer';
import TopicPath from '../../components/common/atoms/TopicPath';
import H1 from '../../components/common/atoms/H1';
import { useParams } from 'react-router-dom';
import NewsListContent from '../../components/news/organisms/NewsListContent';
import NewsNavContainer from '../../components/common/molecules/NewsNavContainer';

/**
 * 新着記事ページ
 * @returns 
 */
function NewsTemplate() {
  // ニュース一覧取得 (日付の降順でソート)
  const news = useMemo(() => {
    return {
      news: [...newsData.news].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()),
    };
  }, []);
  // URLパラメータからindex番号取得
  const { index } = useParams();
  const achievementsIndex = Number(index);
  const isValidIndex = achievementsIndex >= 0 && achievementsIndex < news.news.length;
  const newsEntry = isValidIndex ? news.news[achievementsIndex] : undefined;
  // 表示ニュース設定
  interface linksNav {
    link: string;
    pageName: string | undefined;
  }
  const [beforeLinks, setBeforeLinks] = useState<linksNav | undefined>();
  const [afterLinks, setAfterLinks] = useState<linksNav | undefined>();
  const links = [
    {link:'/news', pageName:textData['menu.news']['ja']},
    {link:`/news/${index}`, pageName:news.news[achievementsIndex].content.ja}
  ];
  const titleText = news.news[achievementsIndex].content.ja;
  const beforeIndex = useMemo(() => achievementsIndex + 1 > 0 && achievementsIndex + 1 < news.news.length ? achievementsIndex + 1 : null, [achievementsIndex, news.news.length]);
  const afterIndex = useMemo(() => achievementsIndex - 1 >= 0 ? achievementsIndex - 1 : null, [achievementsIndex]);
  /**
   * 次の記事と前の記事の設定処理
   */
  useEffect(() => {
    if (beforeIndex !== null) {
      setBeforeLinks({link: `/news/${beforeIndex}`, pageName:news.news[beforeIndex].content.ja});
    } else {
      setBeforeLinks(undefined);
    }
    if (afterIndex!== null) {
      setAfterLinks({link: `/news/${afterIndex}`, pageName:news.news[afterIndex].content.ja});
    } else {
      setAfterLinks(undefined);
    }

  }, [beforeIndex, afterIndex, news.news]);
  return (

    <div>
      <HelmetProvider>
        <Helmet>
          <title>{textData['home.title']['ja']}</title>
          <meta name="description" content= {news.news[achievementsIndex].content.ja} />
        </Helmet>
      </HelmetProvider>

      <header>
        <Header />
      </header>
      {isValidIndex && newsEntry ? (
        <main className="main-content">
          <TopicPath links={links} />
          <H1 text={titleText}/>
          <NewsListContent newsData={news.news[achievementsIndex]}/>
          <NewsNavContainer beforeLinks={beforeLinks} afterLinks={afterLinks}/>
        </main>
      ) : (
        <div>コンテンツが見つかりません。</div>
      )}
      <footer>
        <Footer />
      </footer>

    </div>
  );
}

export default NewsTemplate;
