/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react';

interface ImageHoverSwitchProps {
  initialImage: string;
  hoverImage: string;
}

/**
 * ホバースイッチ画像挿入
 * @param initialImage string
 * @param hoverImage string
 * @returns 
 */
const ImageHoverSwitch:React.FC<ImageHoverSwitchProps> = ({ initialImage, hoverImage }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ display: 'inline-block' }}
    >
      <img
        src={isHovered ? hoverImage : initialImage}
        alt="image"
        width={250}
        style={{ borderRadius: 30 }}
      />
    </div>
  );
};

export default ImageHoverSwitch;
