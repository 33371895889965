import React from 'react';
import Slider from 'react-slick';
import { Box } from '@mui/material';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CaseContainer from './CaseContainer';

interface CaseSliderProps {
    achievementCase: {
        title: {
            ja: string;
            en: string;
        };
        item: {
            ja: string[];
            en: string[];
        };
        text: {
            ja: string;
            en: string;
        };
    }[];
}

/**
 * 実績スライドコンポーネント
 * @param achievements Achievement[]
 * @returns 
 */
const CaseSlider: React.FC<CaseSliderProps> = ({ achievementCase }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    responsive: [
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  return (
    <Slider {...settings}>
      {achievementCase.map((item, index) => (
        <Box key={index}>
            <CaseContainer title={item.title.ja} items={item.item.ja} text={item.text.ja}/>
        </Box>
      ))}
    </Slider>
  );
};

export default CaseSlider;
