import { Box, Typography } from '@mui/material';
import '../../../App.css';
import colorData from '../../../json/color.json';

type props = {
  text: string;
}

/**
 * テキストコンポーネント
 * @param text string
 * @returns 
 */
const Text: React.FC<props> = ({ text }) => {
  
  const textStyle ={
    color:colorData['black'],
    fontSize:24,
    textAlign: 'left',
    whiteSpace: 'pre-wrap',
    lineHeight: 2
  }
 

  return (
    <Box style={{paddingTop:30}}>
      <Typography sx={textStyle}>
       {text}
      </Typography>
    </Box>
  );

}

export default Text;
