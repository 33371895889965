import React, { useState, useEffect } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Fab from '@mui/material/Fab';
import colorData from '../../../json/color.json';

/**
 * スクロールトップボタンコンポーネント
 * @returns 
 */
const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Fab 
      aria-label="scroll back to top" 
      onClick={scrollToTop}
      sx={{
        position: 'fixed',
        bottom: 50,
        right: 50,
        color: colorData['royalblue'],
      }}
    >
      <ArrowUpwardIcon />
    </Fab>
  );
};

export default ScrollToTopButton;
