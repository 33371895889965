import '../../App.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import textData from '../../json/text.json';
import Header from '../../components/menu/organisms/Header';
import Footer from  '../../components/footer/Footer';
import TopicPath from '../../components/common/atoms/TopicPath';
import H1 from '../../components/common/atoms/H1';
import BackgroundAnimation from '../../components/motion/atoms/BackgroundAnimation';
import StaffContent from '../../components/company/organisms/StaffContent';

/**
 * 社員紹介ページ
 * @returns 
 */
function Staff() {
  const links = [{link:'/staff', pageName:textData['menu.company.staff']['ja']}];
  const titleText = textData['menu.company.staff']['ja'];
  return (

    <div>
      <BackgroundAnimation />
      <HelmetProvider>
        <Helmet>
          <title>{textData['home.title']['ja']}</title>
          <meta name="description" content= {textData['menu.company.staff']['ja']} />
        </Helmet>
      </HelmetProvider>

      <header>
        <Header />
      </header>
      
      <main className="main-content">
        <TopicPath links={links} />
        <H1 text={titleText}/>
        <StaffContent />
      </main>
      <footer>
        <Footer />
      </footer>

    </div>
  );
}

export default Staff;
