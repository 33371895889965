import React from 'react';
import OverviewContentGroups from './OverviewContentGroups';
import ContainerContents from '../../common/molecules/ContainerContents';

/**
 * 企業概要
 * @returns 
 */
const OverviewContent: React.FC = () => {
  return (
    <ContainerContents children={<OverviewContentGroups />}/>
  );
};

export default OverviewContent;
