import React from 'react';
import '../../../App.css';
import { Link } from 'react-router-dom';
import colorData from '../../../json/color.json';
import styled from 'styled-components';

type props = {
  url: string;
  name: any;
  fontSize: number;
  borderRadius: number;
  padding: string;
}

const StyledLink = styled(Link)`
color: inherit;
text-decoration: none;
transition: background-color 0.3s, color 0.3s;
&:hover {
  background-color: ${colorData['royalblue']};
}`;

/**
 * グリーンボタンコンポーネント
 * @param url string
 * @param name any
 * @param fontSize number
 * @param borderRadius number
 * @param padding string
 * @returns
 */
const RightGreenButton: React.FC<props> = ({ url, name, fontSize, borderRadius, padding}) => {

  const buttonStyle = {
    border: `10px solid ${colorData['royalblue']}`,
    color: colorData['white'],
    fontSize: fontSize,
    transition: 'opacity 0.3s ease',
    borderRadius: borderRadius,
    padding: padding,
  }

  return (
    <div>
      <StyledLink
        style={buttonStyle}
        to={url}
      >
        {name}
      </StyledLink>
    </div>
  );
};

export default RightGreenButton;
