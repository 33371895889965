import React from 'react';
import AccessContentGroups from './AccessContentGroups';
import ContainerContents from '../../common/molecules/ContainerContents';

/**
 * アクセス
 * @returns 
 */
const AccessContent: React.FC = () => {
  return (
    <ContainerContents children={<AccessContentGroups />}/>
  );
};

export default AccessContent;
