import React from 'react';
import PhilosophyContentGroups from './PhilosophyContentGroups';
import ContainerContents from '../../common/molecules/ContainerContents';

/**
 * 経営理念
 * @returns 
 */
const PhilosophyContent: React.FC = () => {
  return (
    <ContainerContents children={<PhilosophyContentGroups />}/>
  );
};

export default PhilosophyContent;
