import React from 'react';
import { Box, Typography } from '@mui/material';
import textData from '../../../json/text.json';
import colorData from '../../../json/color.json';
import ImageLink from '../atoms/ImageLink';

const ConseptSection: React.FC = () => {
  return (
    <Box
      sx={{
        minHeight: '90vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colorData['white'],
        padding: {xs: '10px', lg: '30px', xl: '50px'},
        textAlign: 'center',
        whiteSpace: 'pre-wrap',
      }}
    >
      <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
        {textData['top.service']['ja']}
      </Typography>
      <ImageLink imageUrl='/images/photo/consulting_image.png' title={textData['service.consulting']['ja']} linkTo='/concept#consulting'/>
      <Box sx={{mt: 5}}></Box>
      <ImageLink imageUrl='/images/photo/develop_image.png' title={textData['service.development']['ja']} linkTo='/concept#development'/>
      <Box sx={{mt: 5}}></Box>
      <ImageLink imageUrl='/images/photo/eco_image.png' title={textData['service.eco']['ja']} linkTo='/concept#eco'/>
    </Box>
  );
};

export default ConseptSection;
