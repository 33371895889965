import '../App.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import textData from '../json/text.json';
import Header from '../components/menu/organisms/Header';
import Footer from  '../components/footer/Footer';
import TopicPath from '../components/common/atoms/TopicPath';
import H1 from '../components/common/atoms/H1';
import BackgroundAnimation from '../components/motion/atoms/BackgroundAnimation';
import ResearchContent from '../components/research/organisms/ResearchContent';

/**
 * 研究開発ページ
 * @returns 
 */
function Research() {
  const links = [{link:'/research', pageName:textData['menu.research']['ja']}];
  const titleText = textData['menu.research']['ja'];
  return (

    <div>
      <BackgroundAnimation />
      <HelmetProvider>
        <Helmet>
          <title>{textData['home.title']['ja']}</title>
          <meta name="description" content= {textData['menu.works']['ja']} />
        </Helmet>
      </HelmetProvider>

      <header>
        <Header />
      </header>
      
      <main className="main-content">
        <TopicPath links={links} />
        <H1 text={titleText}/>
        <ResearchContent />
      </main>
      <footer>
        <Footer />
      </footer>

    </div>
  );
}

export default Research;
