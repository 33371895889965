import React from 'react';
import ConceptContentGroups from './ConceptContentGroups';
import ContainerContents from '../../common/molecules/ContainerContents';

/**
 * キメラワークスとは
 * @returns 
 */
const ConceptContent: React.FC = () => {
  return (
    <ContainerContents children={<ConceptContentGroups />}/>
  );
};

export default ConceptContent;
