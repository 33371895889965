import React from 'react';
import colorData from '../../../json/color.json';

interface props {
  text: string;
}

/**
 * ライン付きテキスト
 * @param text string
 * @returns 
 */
const LineText:React.FC<props> = ({ text }) => {
  const lineStyle = {
    display: 'inline-block',
    borderBottom: `4px solid ${colorData['crimson']}`,
    borderLeft: `4px solid ${colorData['royalblue']}`,
    paddingLeft: '8px',
    paddingBottom: '4px',
    fontSize: '20px',
    fontWeight: 'bold'
  };

  return (
    <div style={lineStyle}>
      {text}
    </div>
  );
};

export default LineText;
