import '../../../App.css';
import Rhombus2D from '../atoms/Rhombus2D';
import Rhombus3D from '../atoms/Rhombus3D';

/**
 * ロゴ台形モーション
 * @returns 
 */
function Logo_rhombus() {

  return (
      <div>        
        <Rhombus3D />
        <Rhombus2D /> 
      </div>
  );
}

export default Logo_rhombus;
