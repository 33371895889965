import React from 'react';
import type {NewsAchievement} from '../../../types/types';
import RecordSelection from '../molecules/RecordSelection';

interface Props {
  newsList: NewsAchievement[];
}

/**
 * お知らせ一覧のコンテンツ群
 * @returns 
 */
const NewsContentGroups: React.FC<Props> = ({newsList}) => {
  return (
    <div>
      <RecordSelection newsList={newsList}/>
    </div>
  );
};

export default NewsContentGroups;
