import '../../../App.css';
import textData from '../../../json/text.json';
import HamburgerIcon from '../atoms/HamburgerIcon';
import SpSingleMenu from '../atoms/SpSingleMenu';

/**
 * スマホ版メニュー
 * @returns 
 */
function SpMenu() {
  return (
    <div style={{ padding: '20px 0 ', textAlign: 'center' }}>
      <HamburgerIcon>
        <div style={{ paddingTop: 60 }}>
          <SpSingleMenu url='/overview' name={textData['menu.company.overview']['ja']}  fontSize={16} blank={0}/>
          <SpSingleMenu url='/concept' name={textData['menu.concept']['ja']}  fontSize={16} blank={0}/>
          <SpSingleMenu url='/access' name={textData['menu.access']['ja']}  fontSize={16} blank={0}/>
          <SpSingleMenu url='/news' name={textData['menu.news']['ja']}  fontSize={16} blank={0}/>
        </div>
      </HamburgerIcon>
    </div>
  );
}

export default SpMenu;
