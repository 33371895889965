import React from 'react';
import '../../../App.css';
import { motion } from 'framer-motion';

type Props = {
  text: String,
  time: number,
}

/**
 * テキストモーション
 * @param text String
 * @param time number
 * @returns 
 */
const Text: React.FC<Props> = ({ text, time }) => {

  return (
    <div>

      <div style={{ paddingTop: 5 }}>
        <motion.article
          initial="hidden"
          animate="visible"
          exit={{ opacity: 0, transition: { duration: 0.3 } }}
          variants={{ visible: { transition: { staggerChildren: 1 } } }}
        >
          <motion.div
            initial="hidden"
            animate="visible"
            variants={{
              hidden: { opacity: 0, y: 30 },
              visible: { opacity: 1, y: 0 },
            }}
            transition={{ duration: 0.3, delay: time }}

          >
            {text}
          </motion.div>
        </motion.article>
      </div>



    </div>

  );
}

export default Text;
